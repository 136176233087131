import { FunctionComponent, useState, useContext, ReactNode } from "react";

import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import FormGroup from "@mui/material/FormGroup";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import CloseIcon from "@mui/icons-material/Close";


import { createChatInstance } from "utils/ChatBackendRequests";
import AppContext from "contexts/AppContext";
import AssetContext from "contexts/AssetContext";
import SearchBar from "components/LeftSidebar/AssetAccordion/AssetList/SearchBar";
import { AssetManufacturerProps, AssetModelProps, AssetProps, AssetSubTypeProps } from "utils/AssetUtils";

interface ChatCreationModalProps {
    isOpen: boolean;
    handleClose: () => void;
}


const ChatCreationModal: FunctionComponent<ChatCreationModalProps> = (props): JSX.Element => {
    const ctx = useContext(AppContext);
    const assetCtx = useContext(AssetContext);
    const [displayName, setDisplayName] = useState<string>("")
    const [selectedAsset, setSelectedAsset] = useState<number>(0);

    function handleSubmit() {
        if (ctx.userId === undefined) return
        createChatInstance(selectedAsset, displayName).then((data) => {
            props.handleClose();
            ctx.setChatId(data.ChatInstanceID);
            assetCtx.setSelectedId(selectedAsset);
        }).catch((error) => {
            console.log("ChatCreationModal.tsx: Encountered error while creating chat:")
            console.log(error)
        })

        setSelectedAsset(0);
        setDisplayName("");
    }

    function ModalWrapper(innerElement: ReactNode): JSX.Element {
        return <Modal
            open={props.isOpen}
            onClose={props.handleClose}
            closeAfterTransition
            // slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: { timeout: 500 },
            }}
        >
            <Fade in={props.isOpen}>
                <div className="chat-creation-modal-container">
                    <Paper className="chat-creation-modal">
                        <div className="frsbc">
                            <Typography variant="h5">Create new chat</Typography>
                            <IconButton onClick={props.handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <Divider sx={{ mb: 1, mt: 1 }} />
                        <div className="chat-creation-form-container">
                            {ctx.userId === undefined
                                ? <Typography>You must be logged in to create a chat.</Typography>
                                : <>{innerElement}</>
                            }
                        </div>
                    </Paper>
                </div>
            </Fade>
        </Modal >;
    }

    return ModalWrapper(<>
        <FormGroup style={{
            marginTop: "1em",
            marginBottom: "1em",
        }}>
            <FormControl fullWidth>
                <TextField
                    label="Chat name"
                    variant="outlined"
                    value={displayName}
                    inputProps={{
                        maxLength: 128,
                    }}
                    onChange={(e) => {
                        setDisplayName(e.target.value)
                    }}
                />
            </FormControl>
            <Divider sx={{ m: 2 }} />
            <FormControl>
                <InputLabel id="chat-creation-asset-select-label">Asset</InputLabel>
                <Select
                    labelId="chat-creation-asset-select-label"
                    label="Asset"
                    value={selectedAsset}
                    defaultValue={0}
                    onChange={(e) => {
                        // @ts-ignore
                        setSelectedAsset(parseInt(e.target.value));
                    }}
                >
                    <MenuItem value={0}>
                        <Typography>
                            None
                        </Typography>
                    </MenuItem>
                    {/* <SearchBar onChange={() => { }} /> */}
                    {Array.from(assetCtx.assets).map((pair: [number, AssetProps], i: number) => {
                        const model: AssetModelProps | undefined = assetCtx.assetModels.get(pair[1].modelId)
                        const subtype: AssetSubTypeProps | undefined = model === undefined ?
                            undefined : assetCtx.assetSubTypes.get(model.subTypeId);
                        const manufacturer: AssetManufacturerProps | undefined = model === undefined ?
                            undefined : assetCtx.assetManufacturers.get(model.manufacturerId)

                        return <MenuItem
                            key={i}
                            value={pair[0]}>
                            <div
                                style={{
                                    width: "85%",
                                    overflowX: "visible",
                                }}
                            >
                                <Typography noWrap>
                                    <strong>{pair[1].name}</strong>
                                </Typography>
                                <Typography fontSize="small" noWrap>
                                    {model?.name ?? "Unknown model"} &#xB7;&nbsp;
                                    {subtype?.name ?? ""} &#xB7;&nbsp;
                                    {manufacturer?.name ?? ""}
                                </Typography>
                            </div>
                        </MenuItem>
                    })}
                </Select>
            </FormControl>
        </FormGroup>
        <div className="chat-creation-buttons-container">
            <Button variant="outlined" onClick={props.handleClose}>
                Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary" onClick={handleSubmit}>
                Submit
            </Button>
        </div>
    </>);
}

export default ChatCreationModal;
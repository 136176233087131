import 'App.css';
import { useState, useRef, useEffect } from 'react';
import AppContext, { AppContextProps } from "contexts/AppContext";
import DocumentContext, { DocumentContextProps } from "contexts/DocumentContext";

import { RightSidebar } from "components/RightSidebar";
import { LeftSidebar } from 'components/LeftSidebar';
import { Chat } from 'components/Chat';
import AssetContext, { AssetContextProps } from 'contexts/AssetContext';

import * as ast from "utils/AssetUtils";
import { DocumentDataProps } from 'utils/DocumentUtils';
import { LoginPageContainer } from 'components/Authentication';
import { getUserInfo, login, register } from 'utils/AuthRequests';
import * as session from 'utils/Session';
import { getCurrentSession } from 'utils/Session';


// TODO: put this into a file, or remove altogether

const FOREMAN_XAI_IDENTIFIER: string = "foreman";
const FOREMAN_ALPHA_IDENTIFIER: string = "foremanalpha";
const LIBRARIAN_IDENTIFIER: string = "librarian";
export { FOREMAN_ALPHA_IDENTIFIER, FOREMAN_XAI_IDENTIFIER, LIBRARIAN_IDENTIFIER };


// TODO: Probably not ideal to have so many useStates in the parent.
// Fix this second thing after documenting backend after webinar.
function App() {

    const [userId, setUserId] = useState<number | undefined>(undefined);

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isLoggingIn, setLoggingIn] = useState<boolean>(false);
    const [isRegistering, setRegistering] = useState<boolean>(false);

    const [errorShown, setErrorShown] = useState<number | null>(null);
    const [errorMessage, setErrorMessage] = useState<string>("");

    const [chatId, setChatId] = useState<number | undefined>(undefined);  // TODO: actual cookies
    const [verbosity, setVerbosity] = useState<number>(0);
    const [architecture, setArchitecture] = useState<string>(FOREMAN_XAI_IDENTIFIER);

    const [focusMode, setFocusMode] = useState<boolean>(false);

    const [assetTypes, setAssetTypes] = useState(new Map<number, ast.AssetTypeProps>());
    const [assetSubTypes, setAssetSubTypes] = useState(new Map<number, ast.AssetSubTypeProps>());
    const [assetManufacturers, setAssetManufacturers] = useState(new Map<number, ast.AssetManufacturerProps>());
    const [assetModels, setAssetModels] = useState(new Map<number, ast.AssetModelProps>());
    const [assets, setAssets] = useState(new Map<number, ast.AssetProps>());
    const [selectedId, setSelectedId] = useState<number | undefined>(undefined);

    const [documentId, setDocumentId] = useState<number | undefined>(undefined);
    const [documentMap, setDocumentMap] = useState<Map<number, DocumentDataProps>>(new Map());

    const pageRefs = useRef({});
    const [tabValue, setTabValue] = useState<number>(0);

    const appContext: AppContextProps = {
        chatId: chatId,
        setChatId: setChatId,
        userId: userId,
        setUserId: setUserId,
        verbosity: verbosity,
        setVerbosity: setVerbosity,
        modelArchitecture: architecture,
        setModelArchitecture: setArchitecture,
        foo: "Development build"
    };

    const documentContext: DocumentContextProps = {
        documentMap: documentMap,
        setDocumentMap: setDocumentMap,
        documentId: documentId,
        setDocumentId: setDocumentId,
        documentPageRefs: pageRefs,
        tabValue: tabValue,
        setTabValue: setTabValue,
    }

    const assetContext: AssetContextProps = {
        assetTypes: assetTypes,
        assetSubTypes: assetSubTypes,
        assetManufacturers: assetManufacturers,
        assetModels: assetModels,
        assets: assets,
        setAssetTypes: setAssetTypes,
        setAssetSubTypes: setAssetSubTypes,
        setAssetManufacturers: setAssetManufacturers,
        setAssetModels: setAssetModels,
        setAssets: setAssets,
        selectedId: selectedId,
        setSelectedId: setSelectedId,
    }

    useEffect(() => {
        const session = getCurrentSession()
        getUserInfo(session?.token).then(res => {
            if (res) {
                setUserId(res.UserID);
            }
            setIsLoading(false);
        });
    }, []);

    function loginAction(email: string, password: string, rememberMe: boolean = true) {
        setErrorShown(null);
        setLoggingIn(true);

        login(email, password, rememberMe).then(response => {
            // 
            switch (response.status) {
                case 200:
                    response.json().then((result) => {
                        session.setSessionTokenAndRefresh(result.token, result.expiration);
                        window.location.reload();
                    });
                    break;
                case 400:
                case 401:
                    setErrorShown(4);  // Bad credentials
                    break;
                case 500:
                    setErrorShown(5);  // Server error
                    break;
                case 403:
                    setErrorShown(3);  // No early access
                    setErrorMessage("Early access not yet granted. Please contact us at info@percev.co.");
                    break;
                default:
                    setErrorShown(1);  // Generic error
                    break;
            }
        }).catch((error) => {
            console.log(error);
            setErrorShown(1);  // Generic error
        }).finally(() => {
            setLoggingIn(false);

        })
    }

    function registerAction(
        email: string,
        password: string,
        firstName: string,
        lastName: string,
        companyName: string
    ): void {
        setErrorShown(null);
        setRegistering(true);

        register(email, password, firstName, lastName, companyName).then(response => {
            switch (response.status) {
                case 200:
                    response.json().then((result) => {
                        setErrorShown(-1);
                    })
                    break;
                case 400:
                    setErrorShown(3);
                    response.json().then((result) => {
                        let errorMessage: any;
                        if (result.errors !== undefined) {
                            errorMessage = result.errors;
                        } else {
                            errorMessage = result;
                        }
                        setErrorMessage(JSON.stringify(errorMessage))
                    })
                    break;
                default:
                    setErrorShown(1);
            }
        }).finally(() => {
            setRegistering(false);
        })
    }

    function renderMainContent(): JSX.Element {
        return <div className="app-container">
            <div className="main-container">
                <LeftSidebar logoutAction={session.logoutAction} />
                <Chat
                    focusMode={focusMode}
                    setFocusMode={setFocusMode}
                />
                <RightSidebar chatId={chatId} />
            </div>
        </div>
    }

    return (
        <AppContext.Provider value={appContext}>
            <DocumentContext.Provider value={documentContext}>
                <AssetContext.Provider value={assetContext}>
                    {isLoading || (userId === undefined
                        ? <LoginPageContainer
                            isLoggingIn={isLoggingIn}
                            loginAction={loginAction}
                            isRegistering={isRegistering}
                            registerAction={registerAction}
                            errorShown={errorShown}
                            errorMessage={errorMessage}
                            setErrorShown={setErrorShown}
                        />
                        : renderMainContent()
                    )}
                </AssetContext.Provider>
            </DocumentContext.Provider>
        </AppContext.Provider>
    );
}

export default App;

import { createTheme } from "@mui/material";

const defaultTheme = createTheme({
    palette: {
        primary: {
            main: "#292562"
        }
    },

    typography: {
        fontSize: 12,
        fontFamily: [
            'Barlow',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(",")
    }
});

export default defaultTheme;
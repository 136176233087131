import { FunctionComponent, useContext, MutableRefObject } from "react";
import Chip from "@mui/material/Chip";
import DocumentContext from "contexts/DocumentContext";

interface EmbeddedCitationProps {
    document_id: number | undefined;
    section_id: number;
}
// TODO: Clean up helpers
export function scrollToPage(pageNumber: number, pageRefs: MutableRefObject<any> | undefined) {
    if (pageRefs === undefined) return;
    const page = pageRefs.current[pageNumber];

    if (page == undefined) return;
    page.scrollIntoView({ behavior: "smooth" });
}

const EmbeddedCitation: FunctionComponent<EmbeddedCitationProps> = (props): JSX.Element => {
    const ctx = useContext(DocumentContext);

    return (
        <Chip
            variant="outlined"
            sx={{ mr: 1 }}
            size="small"
            color="primary"
            label={(props.document_id === undefined ? "" : `Doc: ${ctx.documentMap.get(props.document_id)?.name ?? "Unknown document #" + props.document_id}, `) + `p. ${props.section_id}`}
            // label={`|${props.document_id}:${props.section_id}|`}
            onClick={() => {
                if (ctx.documentId !== props.document_id) ctx.setDocumentId(props.document_id);
                ctx.setTabValue(1);
                scrollToPage(props.section_id, ctx.documentPageRefs);
            }}
        />
    );
}

export default EmbeddedCitation;
import { ChangeEvent, FunctionComponent, useEffect, useState } from "react";

import Typography from "@mui/material/Typography";

import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { AssetManufacturerProps } from "utils/AssetUtils";
import SearchBar from "../../AssetList/SearchBar";
import Box from "@mui/material/Box";
import Snackbar from "@mui/material/Snackbar";
import { createNewManufacturer } from "utils/AssetBackendRequests";
import { cleanAndLowercase } from "utils/MiscUtils";

interface ManufacturerSelectionProps {
    manufacturerId: number;
    setManufacturerId: (arg0: number) => void;
    manufacturers: Map<number, AssetManufacturerProps>;
    refreshAssetData: (callback?: () => void) => void;
}

const ManufacturerSelection: FunctionComponent<ManufacturerSelectionProps> = (props): JSX.Element => {
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);

    const [searchPattern, setSearchPattern] = useState<string>("");
    const [lastCreatedManufacturerName, setLastCreatedManufacturerName] = useState<string | null>(null);
    const [waiting, setWaiting] = useState<boolean>(false);
    const processedPattern = cleanAndLowercase(searchPattern);

    function handleCreate() {
        setWaiting(true);
        createNewManufacturer(searchPattern, false).then(() => {
            props.refreshAssetData();
            setLastCreatedManufacturerName(searchPattern);
            setTimeout(() => {
                setWaiting(false);
                setSnackbarOpen(true);
            }, 520)
        });
    }

    function searchBarOnChange(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
        setSearchPattern(e.target.value);
        e.stopPropagation();
    }

    function matchAgainstSearch(s: string): boolean {
        if (searchPattern.length === 0) return true;
        else return cleanAndLowercase(s).includes(processedPattern);
    }

    function renderSelectedItem(id: number) {
        const name = id === 0 ? "None" : (props.manufacturers.get(id)?.name ?? "None")
        return <MenuItem value={id}>
            <Typography>{
                "ᅟ" + name
                // String above starts with a Hangul Choseong Filler (U+115F).
            }
            </Typography>
        </MenuItem>
    }

    return <>
        <Snackbar
            open={snackbarOpen}
            autoHideDuration={8000}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
            }}
            onClose={() => { setSnackbarOpen(false); }}
            message="Manufacturer created"
        />
        <FormControl>
            <InputLabel id="model-creation-manufacturer-select-label">Manufacturer</InputLabel>
            <Select
                labelId="model-creation-manufacturer-select-label"
                label="Manufacturer"
                value={props.manufacturerId}
                defaultValue={0}
                onChange={(e) => {
                    props.setManufacturerId(Number(e.target.value));
                }}
                onOpen={() => {
                    setSearchPattern("");
                    setWaiting(false);
                }}
            >
                {renderSelectedItem(props.manufacturerId)}
                <Divider />
                <SearchBar onChange={searchBarOnChange} placeholder="Search or create new" />
                {Array.from(props.manufacturers).map((pair: [number, AssetManufacturerProps], i: number) => {
                    if (pair[0] === props.manufacturerId || !matchAgainstSearch(pair[1].name)) return null;
                    return <MenuItem key={i} value={pair[0]}>
                        <Typography>
                            {
                                "᠎" + pair[1].name
                                // String above starts with a Mongolian Vowel Separator (U+180E).
                            }
                        </Typography>
                    </MenuItem>
                })}
                {searchPattern.length !== 0 &&
                    <Box style={{
                        maxWidth: "100%"
                    }}>
                        <Typography sx={{
                            color: waiting ? "#888" : "#0398e2",
                            paddingLeft: "1em",
                            paddingRight: "1em",
                            "&:hover": waiting ? {} : {
                                textDecoration: "underline",
                                cursor: "pointer"
                            }
                        }} onClick={(e) => {
                            e.stopPropagation();
                            handleCreate();
                        }}
                        >
                            {
                                waiting ? "Creating manufacturer..."
                                    : searchPattern !== lastCreatedManufacturerName && <>Create new manufacturer "<b>{searchPattern}</b>"</>
                            }
                        </Typography>
                    </Box>
                }
            </Select>
        </FormControl>
    </>
}

export default ManufacturerSelection;
import { createContext } from 'react';
import { DocumentDataProps } from 'utils/DocumentUtils';


interface DocumentContextProps {
    documentMap: Map<number, DocumentDataProps>,
    setDocumentMap: (arg0: Map<number, DocumentDataProps>) => void;
    documentId: number | undefined;
    setDocumentId: (arg0: number | undefined) => void;
    documentPageRefs: React.MutableRefObject<any> | undefined,
    tabValue: number,
    setTabValue: (arg0: number) => void,
}

const DocumentContext = createContext<DocumentContextProps>({
    documentMap: new Map(),
    setDocumentMap: () => {},
    documentId: undefined,
    setDocumentId: () => {},
    documentPageRefs: undefined,
    tabValue: 1,
    setTabValue: () => {},
});

export default DocumentContext;
export type { DocumentContextProps }
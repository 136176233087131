import { FunctionComponent, useState, useContext, ReactNode, ChangeEvent } from "react";

import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import CloseIcon from "@mui/icons-material/Close";

import ChatLibraryTab from "./ChatLibraryTab";
import UploadTab from "./UploadTab";
import Snackbar from "@mui/material/Snackbar";

interface DocumentCreationModalProps {
    chatId: number | undefined;
    alreadyAssociated: number[];
    isOpen: boolean;
    handleClose: () => void;
    refreshAll: () => void;
}


const DocumentCreationModal: FunctionComponent<DocumentCreationModalProps> = (props): JSX.Element => {
    const [tabValue, setTabValue] = useState<number>(1);

    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>("");

    return <>
        <Snackbar
            open={snackbarOpen}
            autoHideDuration={8000}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
            }}
            onClose={() => { setSnackbarOpen(false); }}
            message={snackbarMessage}
        />
        <Modal
            open={props.isOpen}
            onClose={props.handleClose}
            closeAfterTransition
            slotProps={{
                backdrop: { timeout: 500 },
            }}
        >
            <Fade in={props.isOpen}>
                <div className="doc-creation-modal-container">
                    <Paper className="doc-creation-modal">
                        <div className="frsbc">
                            <Tabs value={tabValue} onChange={(event, val) => {
                                setTabValue(val);
                            }}>
                                <Tab disabled={props.chatId === undefined} label="Chat" value={0} aria-controls="tab-panel-1" />
                                <Tab label="Upload" value={1} aria-controls="tab-panel-2" />
                            </Tabs>
                            <IconButton onClick={props.handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <Divider sx={{ mb: 1 }} />
                        <div className="doc-creation-form-container">
                            {tabValue === 0 && props.chatId !== undefined
                                ? <ChatLibraryTab
                                    chatId={props.chatId}
                                    alreadyAssociated={props.alreadyAssociated}
                                    handleClose={props.handleClose}
                                    refreshAll={props.refreshAll}
                                />
                                : <UploadTab
                                    handleClose={props.handleClose}
                                    setTabValue={setTabValue}
                                    refreshAll={props.refreshAll}
                                    switchOrClose={props.chatId !== undefined}
                                    setSnackbarMessage={setSnackbarMessage}
                                    setSnackbarOpen={setSnackbarOpen}
                                    handleCloseModal={props.handleClose}
                                />
                            }
                        </div>
                    </Paper>
                </div>
            </Fade>
        </Modal >
    </>
}

export default DocumentCreationModal;
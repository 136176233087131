import { FunctionComponent, useContext } from "react";

import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import FormLabel from "@mui/material/FormLabel";
import Tooltip from "@mui/material/Tooltip";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";

import HelpIcon from '@mui/icons-material/Help';
import TuneIcon from '@mui/icons-material/Tune';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { ThemedAccordion, ThemedAccordionDetails, ThemedAccordionSummary } from "../LeftSidebar";
import { FOREMAN_ALPHA_IDENTIFIER, FOREMAN_XAI_IDENTIFIER, LIBRARIAN_IDENTIFIER } from "App";
import AppContext, { AppContextProps } from "contexts/AppContext";

interface SettingsAccordionProps {

}

const SettingsAccordion: FunctionComponent<SettingsAccordionProps> = (props): JSX.Element => {
    const ctx: AppContextProps = useContext(AppContext);

    return <ThemedAccordion>
        <ThemedAccordionSummary expandIcon={<ExpandMoreIcon />}>
            <TuneIcon color="primary" />&ensp;<Typography >Advanced settings</Typography>
        </ThemedAccordionSummary>
        <ThemedAccordionDetails>
            <FormControl fullWidth>
                <InputLabel id="architecture-select-label">Architecture</InputLabel>
                <Select
                    labelId="architecture-select-label"
                    value={ctx.modelArchitecture}
                    label="Architecture"
                    sx={{
                        '& .MuiSelect-select': { padding: 1.5 }
                    }}
                    onChange={(e) => { ctx.setModelArchitecture(e.target.value); }}
                >
                    <MenuItem value={FOREMAN_XAI_IDENTIFIER}>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",

                        }}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <Typography><b>Foreman XAI</b></Typography>&ensp;
                                <Chip label="BETA" size="small" variant="outlined" color="info" />
                            </div>
                            <div>
                                <Typography fontSize="0.85em" color="text-secondary">
                                    Designed for assistance with industrial equipment
                                </Typography>
                            </div>
                        </div>
                    </MenuItem>
                    <MenuItem value={LIBRARIAN_IDENTIFIER}>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",

                        }}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <Typography><b>Librarian</b></Typography>&ensp;
                                <Chip label="BETA" size="small" variant="outlined" color="info" />
                            </div>
                            <div>
                                <Typography fontSize="0.85em" color="text-secondary">
                                    General purpose document-based chat on any topic
                                </Typography>
                            </div>
                        </div>
                    </MenuItem>
                    <MenuItem value={FOREMAN_ALPHA_IDENTIFIER}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Typography><b>Foreman Alpha</b></Typography>&ensp;
                            <Chip label="DEPRECATED" size="small" variant="outlined" color="warning" />
                        </div>
                    </MenuItem>
                </Select>
            </FormControl>
            <Divider light sx={{ mt: 1, mb: 1 }} />

            {/* Verbosity settings */}
            <FormControl fullWidth>
                <FormLabel>
                    <Typography color="text.secondary" style={{
                        display: "flex",
                        alignItems: "center",
                    }}>
                        Verbosity&nbsp;
                        <Tooltip placement="top" title={
                            <Typography fontSize="1.25em">Determines which messages are displayed</Typography>
                        }>
                            <HelpIcon fontSize="inherit" />
                        </Tooltip>
                    </Typography>
                </FormLabel>
                <RadioGroup row value={ctx.verbosity} onChange={(e) => {
                    ctx.setVerbosity(parseInt(e.target.value));
                }}>
                    <FormControlLabel value={0} control={<Radio />} label="User-facing" />
                    <FormControlLabel value={1} control={<Radio />} label="Agents" />
                </RadioGroup>
            </FormControl>

            {/* Context pruning strategies */}
            <FormControl fullWidth disabled>
                <FormLabel>
                    <Typography color="text.secondary" style={{
                        display: "flex",
                        alignItems: "center",
                    }}>
                        Pruning strategy&nbsp;
                        <Tooltip placement="top" title={
                            <Typography fontSize="1.25em">
                                Customizes the strategy used by agents to cut down on context size. Faster and cheaper output, but may affect quality. Strategy customization is currently temporarily disabled.
                            </Typography>
                        }>
                            <HelpIcon fontSize="inherit" />
                        </Tooltip>
                    </Typography></FormLabel>
                <FormGroup row>
                    <FormControlLabel checked={ctx.modelArchitecture === FOREMAN_XAI_IDENTIFIER} control={<Checkbox />} label="Forgetful" />
                    <FormControlLabel checked control={<Checkbox />} label="Decimate" />
                    <FormControlLabel checked={ctx.modelArchitecture === LIBRARIAN_IDENTIFIER} control={<Checkbox />} label="Multibranch" />
                    <FormControlLabel checked={false} control={<Checkbox />} label="Adaptive traversal" />
                    <FormControlLabel checked={ctx.modelArchitecture === FOREMAN_XAI_IDENTIFIER} control={<Checkbox />} label="Ancestry scoping" />
                </FormGroup>
            </FormControl>
            {/* <Typography color="text.secondary">ChatID: {ctx.chatId}</Typography> */}
        </ThemedAccordionDetails>
    </ThemedAccordion>;
}

export default SettingsAccordion;

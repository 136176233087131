import { FunctionComponent, useState } from "react";

import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import ValidatedPasswordForms, { validatePasswordInput } from "./ValidatedPasswordForms";

interface RegistrationFormProps {
    setFormTypeValue: (arg0: number) => void;
    registerAction: (
        email: string,
        password: string,
        firstName: string,
        lastName: string,
        companyName: string
    ) => void;
    isRegistering: boolean;
}


const RegistrationForm: FunctionComponent<RegistrationFormProps> = (props): JSX.Element => {
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [passwordConfirm, setPasswordConfirm] = useState<string>("");
    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [companyName, setCompanyName] = useState<string>("");


    function checkValidCredentials(): boolean {
        if (firstName.length == 0
            || lastName.length == 0
            || email.length == 0
            || password.length == 0
            || passwordConfirm.length == 0
        ) return false;

        return validatePasswordInput(password, passwordConfirm);
    }

    function doRegisterAction() {
        if (!checkValidCredentials()) return;
        props.registerAction(email, password, firstName, lastName, companyName);
    }


    return (<FormGroup className="login-page-form-group" onKeyDown={(event) => {
        if (event.key === 'Enter' && !event.shiftKey && !event.ctrlKey) {
            doRegisterAction();
        }
    }}>
        <div>
            <Typography
                color="text.primary"
                style={{
                    textDecoration: "underline",
                    cursor: "pointer"
                }}
                onClick={() => {
                    props.setFormTypeValue(0);
                }}

            >
                Go back to log in
            </Typography>
        </div>
        <Box className="frsbc" style={{ gap: "1em" }}>
            <FormControl fullWidth>
                <TextField
                    required
                    label="First name"
                    variant="outlined"
                    disabled={props.isRegistering}
                    value={firstName}
                    inputProps={{ maxLength: 384 }}
                    onChange={(e) => {
                        setFirstName(e.target.value)
                    }}
                />
            </FormControl>
            <FormControl fullWidth>
                <TextField
                    required
                    label="Last name"
                    variant="outlined"
                    disabled={props.isRegistering}
                    value={lastName}
                    inputProps={{ maxLength: 384 }}
                    onChange={(e) => {
                        setLastName(e.target.value)
                    }}
                />
            </FormControl>
        </Box>
        <FormControl fullWidth>
            <TextField
                label="Company Name"
                variant="outlined"
                disabled={props.isRegistering}
                value={companyName}
                inputProps={{ maxLength: 384 }}
                onChange={(e) => {
                    setCompanyName(e.target.value)
                }}
            />
        </FormControl>
        <Divider />
        <FormControl fullWidth>
            <TextField
                required
                label="Email"
                variant="outlined"
                disabled={props.isRegistering}
                value={email}
                inputProps={{ maxLength: 384 }}
                onChange={(e) => {
                    setEmail(e.target.value)
                }}
            />
        </FormControl>
        <ValidatedPasswordForms
            password={password}
            setPassword={setPassword}
            passwordConfirm={passwordConfirm}
            setPasswordConfirm={setPasswordConfirm}
            formsDisabled={props.isRegistering}
        />
        <Button
            variant="contained"
            fullWidth
            disabled={!checkValidCredentials() || props.isRegistering}
            onClick={doRegisterAction}>
            Create account
        </Button>
    </FormGroup>);
}

export default RegistrationForm;
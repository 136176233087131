import { FunctionComponent, useEffect, useState } from "react";

import CreateIcon from '@mui/icons-material/Create';
import SendIcon from '@mui/icons-material/Send';

import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import { createNewSubType } from "utils/AssetBackendRequests";


interface SubtypeCreationInlineProps {
    typeId: number;
    handleClose: () => void;
}

const SubtypeCreationInline: FunctionComponent<SubtypeCreationInlineProps> = (props): JSX.Element => {
    const [subtypeName, setSubtypeName] = useState<string>("");
    const [waiting, setWaiting] = useState<boolean>(false);
    const emptyName: boolean = subtypeName.length === 0;


    function submitEvent() {
        setWaiting(true);
        createNewSubType(subtypeName, props.typeId, false).then(() => {
            props.handleClose();
        })
    }

    useEffect(() => {
        setSubtypeName("");
    }, [props.typeId])

    return (<div style={{
        // border: "1px red solid",
        width: "100%",
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
        paddingLeft: "0.1725em",
        paddingRight: "2.667em",
        gap: "0.6em"
    }}>
        <CreateIcon color="primary" fontSize="small" />
        <TextField
            value={subtypeName}
            onChange={(e) => {
                setSubtypeName(e.target.value)
            }}
            sx={{ flexGrow: 1 }}
            disabled={waiting}
            placeholder="Enter subtype name" variant="standard"
        />
        <IconButton disabled={waiting || emptyName} onClick={submitEvent}>
            <SendIcon color={waiting || emptyName ? "disabled" : "primary"}></SendIcon>
        </IconButton>
    </div>);
}

export default SubtypeCreationInline;
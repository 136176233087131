

import { FunctionComponent, useContext, useState } from "react";

import Typography from "@mui/material/Typography";
import AssetContext from "contexts/AssetContext";

import Box from "@mui/material/Box";


import AddBoxIcon from "@mui/icons-material/AddBox";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import * as ast from "utils/AssetUtils";
import { FolderAccordion, FolderAccordionDetails, FolderAccordionSummary } from "../FolderAccordions";
import AssetSubTypeEntry from "./AssetSubTypeEntry";
import ListItem from "@mui/material/ListItem";
import Button from "@mui/material/Button";
import { SubtypeCreationInline } from "./Modals";

interface AssetModelList {
    handleOpenModelCreation: (typeId: number | undefined, subTypeId: number | undefined) => void;
    selectedModelId: number;
    setModel: (arg0: number) => void;
    categories: ast.AssetCategorizationMap;
    refreshAssetData: (callback?: () => void) => void;
}

const AssetModelList: FunctionComponent<AssetModelList> = (props): JSX.Element => {
    const assetCtx = useContext(AssetContext);
    const [isCreatingSubtype, setCreatingSubtype] = useState<boolean>(false);
    const [activeTypeId, setActiveTypeId] = useState<number | undefined>(undefined);

    function handleCloseSubtypeCreation() {
        setActiveTypeId(undefined);
        props.refreshAssetData();
        setTimeout(() => { setCreatingSubtype(false) }, 500);
    }

    return <div style={{
        maxHeight: "50vh"
    }}>
        {/* <SearchBar onChange={() => { }} /> */}
        <Box style={{

        }}>
            {Array.from(props.categories).map(([assetType, assetSubTypeMap], i) => {
                const subtypeMaps: [ast.AssetSubTypeProps | undefined, ast.AssetModelProps[]][] = Array.from(assetSubTypeMap);
                let subtypeCount: number = subtypeMaps.length;
                let modelCount: number = 0;
                for (const st of subtypeMaps) {
                    modelCount += st[1].length;
                }

                return <FolderAccordion defaultExpanded={false} key={i} style={{
                    backgroundColor: "#eeeef3",
                }}>
                    <FolderAccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Box className="frsbc" style={{ width: "100%" }}>
                            <Typography>
                                <strong>{assetType?.name ?? "Uncategorized"}</strong>
                            </Typography>
                            <Typography noWrap color="text.secondary" className="type-model-count-typography">
                                {subtypeCount - 1} subtypes, {modelCount} models
                            </Typography>
                        </Box>
                    </FolderAccordionSummary>
                    <FolderAccordionDetails className="type-accordion-details">
                        {Array.from(assetSubTypeMap).map(([assetSubType, models], j) => <AssetSubTypeEntry
                            key={j}
                            subtype={assetSubType}
                            selectedModelId={props.selectedModelId}
                            models={models}
                            manufacturers={assetCtx.assetManufacturers}
                            setModel={props.setModel}
                            handleOpenModelCreation={props.handleOpenModelCreation}
                        />
                        )}
                        <ListItem sx={{
                            padding: "0.1em 1.3875em"
                        }}>
                            {isCreatingSubtype && activeTypeId !== undefined && assetType?.id === activeTypeId ?
                                <SubtypeCreationInline typeId={activeTypeId} handleClose={handleCloseSubtypeCreation} />
                                : assetType !== undefined && <Button className="frsbc create-subtype-button"
                                    onClick={() => {
                                        setActiveTypeId(assetType?.id);
                                        setCreatingSubtype(true);
                                    }}
                                >
                                    <AddBoxIcon fontSize="small" color="primary" />
                                    <Typography className="create-subtype-button-typography" noWrap>
                                        Create new subtype
                                    </Typography>
                                </Button>
                            }
                        </ListItem>
                    </FolderAccordionDetails>
                </FolderAccordion>
            })}
        </Box>
    </div>
}

export default AssetModelList;
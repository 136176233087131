import { FunctionComponent, MouseEvent } from "react";

import PublicIcon from '@mui/icons-material/Public';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import LockIcon from '@mui/icons-material/Lock';
import IconButton from "@mui/material/IconButton";

interface VisibilityProps {
    isPublicShared: boolean;
    isPublicVisible: boolean;
    fontSize?: "small" | "inherit" | "medium" | "large";

    onVisibilityToggle?: (e: MouseEvent) => void;
}

const VisibilityButton: FunctionComponent<VisibilityProps> = (props): JSX.Element => {
    return (<IconButton sx={{ p: "0.25em", borderRadius: "4px" }} disabled onClick={(e) => {
        e.stopPropagation();
        if (props.onVisibilityToggle !== undefined) props.onVisibilityToggle(e);
    }}>
        {props.isPublicShared
            ? <CloudDownloadIcon color="primary" fontSize={props.fontSize} />
            : (props.isPublicVisible
                ? <PublicIcon color="primary"  fontSize={props.fontSize}/>
                : <LockIcon color="primary"  fontSize={props.fontSize}/>
            )
        }
    </IconButton>);
}

export default VisibilityButton;
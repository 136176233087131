import { FunctionComponent, useState } from "react";

import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";


interface LoginFormProps {
    isLoggingIn: boolean;
    loginAction: (email: string, password: string, rememberMe?: boolean) => void;
    setFormTypeValue: (arg0: number) => void;
}

const LoginForm: FunctionComponent<LoginFormProps> = (props): JSX.Element => {
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const isProcessing: boolean = props.isLoggingIn;

    function checkValidCredentials(): boolean {
        if (password.length == 0 || email.length == 0) return false;
        // Do some validation here

        return true
    }

    function doLoginAction() {
        if (!checkValidCredentials()) return;
        props.loginAction(email, password);
    }

    return (<FormGroup className="login-page-form-group" onKeyDown={(event) => {
        if (event.key === 'Enter' && !event.shiftKey && !event.ctrlKey) {
            doLoginAction();
        }
    }}>
        <div>
            <Typography
            color="text.primary"
            style={{
                textDecoration: "underline",
                cursor: "pointer"
            }}
            onClick={() => {
                props.setFormTypeValue(1);
            }}

            >
                Create new account
            </Typography>
        </div>
        <FormControl fullWidth required>
            <TextField
                label="Email"
                variant="outlined"
                disabled={isProcessing}
                value={email}
                inputProps={{ maxLength: 384 }}
                onChange={(e) => {
                    setEmail(e.target.value)
                }}
            />
        </FormControl>
        <FormControl fullWidth required>
            <TextField
                label="Password"
                variant="outlined"
                type="password"
                disabled={isProcessing}
                value={password}
                inputProps={{ maxLength: 384 }}
                onChange={(e) => {
                    setPassword(e.target.value)
                }}
            />
        </FormControl>
        <Button
            variant="contained"
            fullWidth
            disabled={!checkValidCredentials() || isProcessing}
            onClick={doLoginAction}>
            Sign in
        </Button>
        <Typography
        color="text.primary"
        style={{
            textDecoration: "underline",
            cursor: "pointer"
        }}
        onClick={() => {
            props.setFormTypeValue(2);
        }}>
            Reset password
        </Typography>
    </FormGroup>);
}

export default LoginForm;
import { FunctionComponent, useState, useContext, useEffect } from "react";

import Divider from "@mui/material/Divider";
import Fade from "@mui/material/Fade";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import AppContext from "contexts/AppContext";
import AssetContext from "contexts/AssetContext";
import FormGroup from "@mui/material/FormGroup";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import CloseIcon from "@mui/icons-material/Close";


import AssetModelList from "./AssetModelList/AssetModelList";
import { ModelCreationModal } from "./AssetModelList/Modals";
import { AssetCategorizationMap, AssetProps, categorizeModels } from "utils/AssetUtils";

interface AssetCreationModalProps {
    isOpen: boolean;
    handleClose: () => void;
    handleSubmit: (displayName: string, model: number, editingId: number | null) => void;
    refreshAssetData: (callback?: () => void) => void;
    editingAssetId: number | null;
}


const AssetCreationModal: FunctionComponent<AssetCreationModalProps> = (props): JSX.Element => {
    const assetCtx = useContext(AssetContext);
    const editingAsset: AssetProps | null = props.editingAssetId === null ? null
        : assetCtx.assets.get(props.editingAssetId) ?? null

    const [assetName, setAssetName] = useState<string>("");
    const [model, setModel] = useState<number>(0);

    // For model creation
    const [typeId, setTypeId] = useState<number | undefined>(undefined);
    const [subtypeId, setSubtypeId] = useState<number | undefined>(undefined);
    const [openModelCreation, setOpenModelCreation] = useState<boolean>(false);

    const [categories, setCategories] = useState<AssetCategorizationMap>(new Map());

    // Closes the asset creation modal

    function clearAndClose(): void {
        props.handleClose();
        setAssetName("");
        setModel(0);
    }

    // Submits the form

    function submitAction(): void {
        props.handleSubmit(assetName, model, props.editingAssetId);
        setAssetName("");
        setModel(0);
    }

    // Asset model creation open/close:

    function handleOpenModelCreation(typeId: number | undefined, subTypeId: number | undefined) {
        setTypeId(typeId);
        setSubtypeId(subTypeId);
        setOpenModelCreation(true);
    }

    function handleCloseModelCreation() {
        setOpenModelCreation(false);
        props.refreshAssetData();
    }

    useEffect(() => {
        setCategories(categorizeModels(assetCtx.assetModels, assetCtx.assetSubTypes, assetCtx.assetTypes));
    }, [assetCtx.assetModels, assetCtx.assetSubTypes, assetCtx.assetTypes])

    useEffect(() => {
        setAssetName(editingAsset?.name ?? "")
        setModel(editingAsset?.modelId ?? 0)
    }, [editingAsset, props.editingAssetId])

    return <Modal
        open={props.isOpen}
        onClose={handleCloseModelCreation}
        closeAfterTransition
    >
        <Fade in={props.isOpen}>
            <div className="asset-creation-modal-container">
                <Paper className="asset-creation-modal">
                    <div className="frsbc">
                        {editingAsset === null ?
                            <Typography variant="h5">Creating new asset</Typography> :
                            <Typography variant="h5">Editing asset "<b>{editingAsset.name}</b>"</Typography>
                        }
                        <IconButton onClick={clearAndClose}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <Divider sx={{ mb: 1, mt: 1 }} />
                    <div className="asset-creation-form-container">
                        {props.isOpen && <>
                            <ModelCreationModal
                                isOpen={openModelCreation}
                                handleClose={handleCloseModelCreation}
                                typeId={typeId}
                                subtypeId={subtypeId}
                                categories={categories}
                                refreshAssetData={props.refreshAssetData}
                            />

                            <FormGroup style={{
                                margin: "1em",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                            }}>
                                <FormControl fullWidth sx={{ mb: "1em" }} >
                                    <TextField
                                        required
                                        label="Asset name"
                                        variant="outlined"
                                        value={assetName}
                                        inputProps={{ maxLength: 128 }}
                                        onChange={(e) => { setAssetName(e.target.value); }}
                                    />
                                </FormControl>
                                <FormControl fullWidth>
                                    <InputLabel id="asset-creation-asset-select-label">Model</InputLabel>
                                    <Select
                                        disabled={editingAsset !== null}
                                        labelId="asset-creation-asset-select-label"
                                        label="Model"
                                        value={model}
                                        defaultValue={0}
                                        onChange={(e) => {
                                            setModel(Number(e.target.value))
                                        }}
                                    >
                                        {/* This hack is needed for the thing to display which asset is being selected */}
                                        <MenuItem value={model}>
                                            <Typography>
                                                {
                                                    assetCtx.assetModels.get(model)?.name
                                                    ?? "᠎Select a model"
                                                    // String above starts with a Mongolian Vowel Separator (U+180E).
                                                    // There's a very good reason why I needed to put that there.
                                                }
                                            </Typography>
                                        </MenuItem>;

                                        <AssetModelList
                                            handleOpenModelCreation={handleOpenModelCreation}
                                            selectedModelId={model}
                                            setModel={setModel}
                                            categories={categories}
                                            refreshAssetData={props.refreshAssetData}
                                        />
                                    </Select>
                                </FormControl>
                                <FormControlLabel disabled control={
                                    <Checkbox checked={true} />   // TODO: Forced private selection
                                } label="Private" />
                            </FormGroup>
                            <div className="asset-creation-buttons-container">
                                <Button variant="outlined" onClick={clearAndClose}>
                                    Cancel
                                </Button>
                                <Button
                                    disabled={assetName.length === 0 || model === 0}
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    onClick={submitAction}
                                >
                                    Submit
                                </Button>
                            </div></>
                        }
                    </div>
                </Paper>
            </div>
        </Fade>
    </Modal>;
}

export default AssetCreationModal;
import moment from "moment";
import { FunctionComponent, useContext } from "react";
import { useTheme } from "@mui/material/styles";

import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

import AppContext from "contexts/AppContext";
import { ChatInstance } from "utils/ChatUtils";
import AssetContext from "contexts/AssetContext";

interface ChatInstanceEntryProps {  
    chat: ChatInstance;
    handleDelete: () => void;
    handleEdit: () => void;
}

// TODO: Clean up styles
const ChatInstanceEntry: FunctionComponent<ChatInstanceEntryProps> = (
    { chat, handleDelete },
): JSX.Element => {
    const ctx = useContext(AppContext);
    const assetCtx = useContext(AssetContext);
    const theme = useTheme();
    const primaryColor = theme.palette.primary.main;
    const isSelected: boolean = ctx.chatId === chat.ChatInstanceID;

    return (
        <ListItem disablePadding divider>
            <ListItemButton
                style={{
                    backgroundColor: isSelected ? "#e8eaff" : "#fff",
                    zIndex: 20, // Coupled with the icon buttons
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "start",
                    borderLeft: isSelected
                        ? `8px ${primaryColor} solid`
                        : `0px ${primaryColor} solid`,
                    transition: "border 0.1375s ease-out, background 0.1375s linear",
                }}
                onClick={() => {
                    if (isSelected) return;
                    ctx.setChatId(chat.ChatInstanceID);
                    assetCtx.setSelectedId(chat.AssetID);
                }}
            >
                <div
                    style={{
                        width: "66%",
                        overflowX: "clip",
                    }}
                >
                    <Typography noWrap>
                        <strong>{chat.DisplayName}</strong>
                    </Typography>
                    {chat.AssetID === 0
                        ? <Typography fontSize="small" color="text.secondary" sx={{ fontStyle: "italic" }}>
                            No asset
                        </Typography>
                        : <div style={{ display: "flex" }}>
                            <Typography fontSize="small" noWrap>
                                {assetCtx.assets.get(chat.AssetID)?.name ?? `Unknown asset #${chat.AssetID}`}
                            </Typography>
                            <IconButton
                                disabled={!isSelected}
                                style={{
                                    padding: 0,
                                    marginLeft: "0.3125em",
                                    opacity: isSelected ? "100%" : "0%",
                                    transition: "opacity 0.1375s linear",
                                }}
                                size="small" onClick={(e) => {
                                    e.stopPropagation();
                                    if (assetCtx.assets.get(chat.AssetID) === undefined) return;
                                    assetCtx.setSelectedId(chat.AssetID);
                                }}
                            >
                                <ExitToAppIcon color="primary" fontSize="small" />
                            </IconButton>
                        </div>
                    }
                </div>
                <div style={{ flexGrow: 1, marginLeft: "0.33em", position: "relative", alignItems: "end" }} >

                        {/* <IconButton
                            color="inherit"
                            disabled={!isSelected}
                            style={{
                                border: "1px solid #aaa",
                                zIndex: 25, // Coupled with its parent ListItemButton
                                borderRadius: 8,
                                marginRight: 2,
                                opacity: isSelected ? "100%" : "0%",
                                transition: "opacity 0.1375s linear",
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                            }}
                        >
                            <EditIcon />
                        </IconButton> */}
                    <IconButton
                        color="error"
                        disabled={!isSelected}
                        style={{
                            float: "right",
                            border: "1px solid #d88",
                            zIndex: 25, // Coupled with its parent ListItemButton
                            borderRadius: 8,
                            marginLeft: 2,
                            opacity: isSelected ? "100%" : "0%",
                            transition: "opacity 0.1375s linear",
                        }}
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            handleDelete();
                        }}
                    >
                        <DeleteIcon />
                    </IconButton>
                    <Typography
                        color="text.secondary"
                        style={{
                            position: "absolute",
                            width: "100%",
                            textAlign: "right",
                            overflowX: "hidden",
                            opacity: isSelected ? "0%" : "100%",
                        }}
                    >
                        {moment.utc(chat.CreatedOn).local().fromNow()}
                    </Typography>
                </div>
            </ListItemButton>
        </ListItem>
    );
};

export default ChatInstanceEntry;

import { FunctionComponent, MouseEvent, useEffect, useState } from "react";

import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import Typography from "@mui/material/Typography";

import PendingActionsIcon from '@mui/icons-material/PendingActions';
import ArticleIcon from '@mui/icons-material/Article';

import { DocumentDataProps } from "utils/DocumentUtils";
import { useTheme } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import EditIcon from '@mui/icons-material/Edit';
import SendIcon from '@mui/icons-material/Send';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from "@mui/material/TextField";
import { deleteFile, updateDocument } from "utils/DocumentBackendRequests";

interface DocumentEntryProps {
    isSelected: boolean;
    doc: DocumentDataProps;
    onEntryClick: (arg0: number) => void;
    refreshAll: () => void;
    allowEdit?: boolean;
}

const DocumentEntry: FunctionComponent<DocumentEntryProps> = (props): JSX.Element => {
    const theme = useTheme();
    const primaryColor = theme.palette.primary.main;

    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [editName, setEditName] = useState<string>("");
    const [waiting, setWaiting] = useState<boolean>(false);

    function handleStartEdit() {
        setIsEditing(true);
    }

    function handleSubmitEdit() {
        setWaiting(true);
        updateDocument(props.doc.id, editName).then(() => {
            props.refreshAll();
            setIsEditing(false);
            setEditName("");
            setWaiting(false);
        })
    }

    function handleDelete() {
        deleteFile(props.doc.id).then(() => {
            props.refreshAll();
        })
    }

    useEffect(() => {
        setIsEditing(false);
        setEditName("");
    }, [props.isSelected])

    return <ListItem disablePadding >
        <ListItemButton
            className="frsbc"
            style={{
                backgroundColor: props.isSelected ? "#e8eaff" : "#fff",
                borderLeft: props.isSelected
                    ? `8px ${primaryColor} solid`
                    : `0px ${primaryColor} solid`,
                transition: "border 0.1375s ease-out, background 0.1375s linear",

            }}
            onClick={(e) => {
                e.stopPropagation();
                props.onEntryClick(props.doc.id);
            }}
        >
            <div className="frsbc" style={{
                width: "100%",
                overflowX: "hidden",
                gap: "1em"
            }}>
                <div style={{
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    alignItems: "center"
                }}> {isEditing
                    ? <>
                        <EditIcon />&ensp;
                        <TextField
                            value={editName}
                            onChange={(e) => { setEditName(e.target.value) }}
                            sx={{ flexGrow: 1 }}
                            disabled={waiting}
                            placeholder="Enter new name" variant="standard"
                        />
                    </> :
                    <>
                        {props.doc.hasBeenProcessed
                            ? <ArticleIcon color="primary"/>
                            : <PendingActionsIcon color="primary"/>
                        }
                        &ensp;
                        <Typography>
                            {props.doc.name}
                        </Typography>
                    </>
                    }
                </div>

                {(props.isSelected && !props.doc.isPublic && (props.allowEdit ?? false)) &&
                    <div className="frsbc" style={{
                        opacity: props.isSelected ? "100%" : "0%",
                        transition: "opacity 0.1375s linear",
                        gap: "0.375em",
                    }}>
                        {
                            isEditing
                                ? <IconButton
                                    color="primary"
                                    disabled={waiting || editName.length === 0}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleSubmitEdit()
                                    }}
                                >
                                    <SendIcon />
                                </IconButton>
                                : <>
                                    <IconButton
                                        color="primary"
                                        disabled={!props.isSelected}
                                        style={{
                                            border: "1px solid #2925628f",
                                            borderRadius: 8,
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleStartEdit()
                                        }}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton
                                        color="error"
                                        disabled={!props.isSelected}
                                        style={{
                                            border: "1px solid #d88",
                                            borderRadius: 8,
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleDelete();
                                        }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </>
                        }
                    </div>
                }
            </div>
        </ListItemButton>
    </ListItem>
}

export default DocumentEntry;
import ENDPOINT from "./EndpointDefinition";
import { SessionAuth, getCurrentSession } from "./Session";

export async function login(
    email: string,
    password: string,
    rememberMe: boolean
): Promise<Response> {
    console.log(`Logging in with ${email}`)

    const response = await fetch(`${ENDPOINT}/Identity/login`,
        {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                Email: email,
                Password: password,
                RememberMe: rememberMe
            })
        }
    )

    return response;
}

export async function register(
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    companyName: string
): Promise<Response> {
    console.log(`Registering with ${email}`)

    const response = await fetch(`${ENDPOINT}/Identity/register`,
        {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                Email: email,
                Password: password,
                ConfirmPassword: password,
                FirstName: firstName,
                LastName: lastName,
                CompanyName: companyName
            })
        }
    )

    return response;
}

export async function sendResetPasswordLink(email: string): Promise<Response> {
    const response = await fetch(`${ENDPOINT}/Identity/send-reset-link`,
        {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                Email: email,
            })
        }
    )

    return response;
}

export async function resetPassword(
    email: string,
    token: string,
    password: string,
): Promise<Response> {
    const response = await fetch(`${ENDPOINT}/Identity/reset-password` +
        `?email=${encodeURIComponent(email)}` +
        `&token=${encodeURIComponent(token)}` +
        `&password=${encodeURIComponent(password)}`,
        {
            method: "GET",
            mode: "cors",
            headers: {
                "accept": "*/*",
            }
        }
    );

    return response;
}

export async function getUserInfo(sessionToken: string | undefined) {
    if (sessionToken == undefined) return undefined;

    const response = await fetch(`${ENDPOINT}/Identity/user-info`,
        {
            method: "GET",
            mode: "cors",
            headers: {
                "Authorization": `Bearer ${sessionToken}`
            }
        }
    )

    return response.json()
}
import "./styles.css";

import { FunctionComponent, useState } from "react";

import logo from "images/percev.png";

import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography"
import IconButton from "@mui/material/IconButton";

import CloseIcon from "@mui/icons-material/Close";

import LoginForm from "./LoginForm";
import RegistrationForm from "./RegistrationForm";
import ResetPasswordForm from "./ResetPasswordForm";

interface LoginPageContainerProps {
    isLoggingIn: boolean;
    loginAction: (email: string, password: string, rememberMe?: boolean) => void;
    isRegistering: boolean;
    registerAction: (email: string,
        password: string,
        firstName: string,
        lastName: string,
        companyName: string
    ) => void;
    errorShown: number | null;
    setErrorShown: (arg0: number | null) => void;
    errorMessage: string;
    initialTab?: number;
}

const LoginPageContainer: FunctionComponent<LoginPageContainerProps> = (props): JSX.Element => {
    // 0 for login, 1 for registration, 2 for reset password
    const [formTypeValue, setFormTypeValue] = useState<number>(props.initialTab ?? 0);

    function renderForm(): JSX.Element {
        switch (formTypeValue) {
            case 0: return <LoginForm
                isLoggingIn={props.isLoggingIn}
                loginAction={props.loginAction}
                setFormTypeValue={setFormTypeValue}
            />;
            case 1: return <RegistrationForm
                setFormTypeValue={setFormTypeValue}
                registerAction={props.registerAction}
                isRegistering={props.isRegistering}
            />
            case 2: return <ResetPasswordForm
                setFormTypeValue={setFormTypeValue}
            />
            default:
                return <></>;
        }
    }

    function renderError(): JSX.Element | null {  // App.tsx/loginAction()
        switch (props.errorShown) {
            case null: return null;
            case 3: return <Typography>
                {props.errorMessage}
            </Typography>
            case 4: return <Typography>
                Login failed, check your credentials.
            </Typography>;
            case 5: return <Typography>
                Internal server error.
            </Typography>;
            case -1: return <Typography>
                Successfully registered. Please check your email
            </Typography>;
            default: return <Typography>
                Something went wrong.
            </Typography>;
        }
    }

    return (<div className="login-page-container">
        <Paper elevation={12} className="login-paper">
            <Box className="frsbc" style={{ width: "100%", justifyContent: "center" }}>
                <a href="https://percevx.ai" target="_blank">
                    <img src={logo} height={64} alt="Foreman XAI by Percēv" />
                </a>
            </Box>
            {renderForm()}
            {props.errorShown &&
                <Box className="frsbc" style={{
                    boxSizing: "border-box",
                    width: "100%",
                    border: " 1px solid #292562",
                    margin: "0.25em 0em",
                    borderRadius: "6px",
                    padding: "0.375em 0.25em 0.375em 1em",
                }}>
                    <div>
                        {renderError()}
                    </div>
                    <IconButton color="primary" onClick={() => { props.setErrorShown(null) }}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            }
            <div>
                <a href="https://www.graceport.com/foremanxai" target="_blank">
                    <Typography color="text.secondary">
                        Request early access
                    </Typography>
                </a>
            </div>
        </Paper>
    </div>);
}

export default LoginPageContainer;
import { FunctionComponent, useState, ChangeEvent } from "react";

import FormGroup from "@mui/material/FormGroup";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import FileUploadIcon from '@mui/icons-material/FileUpload';

import Typography from "@mui/material/Typography";
import { uploadFile } from "utils/DocumentBackendRequests";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Snackbar from "@mui/material/Snackbar";

interface UploadTabProps {
    handleClose: () => void;
    setTabValue: (arg0: number) => void;
    refreshAll: () => void;
    switchOrClose: boolean;
    handleCloseModal: () => void;
    setSnackbarOpen: (arg0: boolean) => void;
    setSnackbarMessage: (arg0: string) => void;

}

const UploadTab: FunctionComponent<UploadTabProps> = (props): JSX.Element => {
    const [displayName, setDisplayName] = useState<string>("");
    const [fileToUpload, setFileToUpload] = useState<File | null>(null);
    const [filePath, setFilePath] = useState<string | null>(null);
    const [isUploading, setUploading] = useState<boolean>(false);
    const [isPrivate, setPrivate] = useState<boolean>(true);

    function handleFileSelect(event: ChangeEvent<HTMLInputElement>) {
        if (event.target.files === null || !event.target.files[0]) return;

        const fileBlob: File = event.target.files[0];
        setFilePath(fileBlob.name);
        setDisplayName(fileBlob.name);
        setFileToUpload(fileBlob);
    }

    function handleSubmit() {  // TODO: Extract to multiple components
        console.log(fileToUpload)
        if (fileToUpload !== null) {
            setUploading(true);
            (async () => await uploadFile(fileToUpload, displayName, 1, null, !isPrivate)
                .then((res) => {
                    props.refreshAll();
                    setTimeout(() => { handleAfterSubmit(res) }, 600);
                })
                .catch((error) => {
                    console.log(error)
                    props.setSnackbarOpen(true);
                    setUploading(false);
                    props.setSnackbarMessage("This document cannot be uploaded")
                })
            )();
        }
    }

    function handleAfterSubmit(res: number) {
        props.setSnackbarOpen(true);
        setUploading(false);
        if (res === 200) {
            props.setSnackbarMessage("Document has been uploaded, proccessing can take up to a few minutes");
            setDisplayName("");
            setFilePath(null);
            setFileToUpload(null);
            if (props.switchOrClose) props.setTabValue(0); else props.handleCloseModal();
        } else {
            props.setSnackbarMessage("Something went wrong while uploading");
        }
    }

    return <>
        <FormGroup style={{
            padding: "0.375em 0.725em 0.625em 0.725em",
            height: "100%"
        }}>
            <TextField
                label="Display name"
                variant="standard"
                required
                value={displayName}
                inputProps={{ maxLength: 128 }}
                onChange={(e) => { setDisplayName(e.target.value) }}
            />

            <div style={{
                display: "flex",
                width: "100%",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "start",
                overflowX: "hidden",
                marginTop: "1.125em"
            }}>
                <Button
                    disabled={isUploading}
                    component="label"
                    variant="outlined"
                    startIcon={<FileUploadIcon />}
                    style={{
                        marginRight: "0.667em",
                        width: "33.75%"
                    }}
                >
                    Upload PDF
                    <input
                        type="file"
                        accept=".pdf"
                        hidden
                        onChange={handleFileSelect}
                    />
                </Button>
                <Typography style={{
                    width: "66.25%"
                }} noWrap>
                    {isUploading ? "Uploading document..." : (filePath ?? "No file selected")}
                </Typography>
            </div>
            <FormControlLabel disabled control={
                <Checkbox
                    checked={true}  // TODO: Forced private selection
                    onChange={(e) => {
                        setPrivate(e.target.checked);
                    }}
                />
            } label="Private" />
        </FormGroup >
        <div className="doc-creation-buttons-container">
            <Button disabled={isUploading} variant="outlined" onClick={props.handleClose}>
                Cancel
            </Button>
            <Button
                disabled={isUploading || displayName.length === 0 || filePath === null}
                type="submit"
                variant="contained"
                color="primary"
                onClick={handleSubmit}
            >
                Submit
            </Button>
        </div>
    </>
}

export default UploadTab;
import "./styles.css";
import { FunctionComponent, useState, memo, MutableRefObject, useContext } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import FitScreenIcon from '@mui/icons-material/FitScreen';

import { scrollToPage } from "components/Chat/EmbeddedCitation";
import DocumentContext from "contexts/DocumentContext";

// https://github.com/wojtekmaj/react-pdf/wiki/Known-issues#syntaxerror-expected-expression-got-
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface PdfDocumentProps {
    documentUrl: string | undefined | null,  // null is unauthorized, undefined is no selection
    documentPageRefs: MutableRefObject<any> | undefined;
}

function renderPages(
    numPages: number | null,
    pageRefs: MutableRefObject<any> | undefined,
    zoom: number
): JSX.Element[] | JSX.Element {
    if (numPages === null) return <></>;
    const linrange: number[] = Array(...Array(numPages)).map((x, i) => i)

    return linrange.map(p => <div key={p} ref={el => {
        if (pageRefs === undefined) return
        pageRefs.current[p] = el;
    }}>
        <Page scale={zoom} pageIndex={p} loading="" />
        <Divider />
    </div>);
}

const PdfDocument: FunctionComponent<PdfDocumentProps> = memo((props): JSX.Element => {
    const [numPages, setNumPages] = useState<number | null>(null);
    const [zoom, setZoom] = useState<number>(1.384);

    function renderDocument(): JSX.Element {
        if (props.documentUrl === undefined) {
            return <Typography> Choose a document </Typography>
        }
        else if (props.documentUrl === null) {
            return <Typography> Document not found; it may not exist, or you may not have permission to access it. </Typography>
        } else {
            return <Document
                file={{ url: props.documentUrl }}
                onLoadSuccess={({ numPages }) => setNumPages(numPages)}
            >
                <div className="pdf-document" >
                    {renderPages(numPages, props.documentPageRefs, zoom)}
                </div>
            </Document>;
        }
    }

    // useEffect(() => {

    // }, [ctx.focusedPageIndex]);
    return <Box className="pdf-container">
        <Box style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center",
            backgroundColor: "#f0f0f0",
            border: "1px solid #ddd",
            borderRadius: "4px",
            marginTop: "0.56em",
            padding: "0.2em",
        }}>
            <Box>
                <div style={{ fontSize: "1.1em", display: "flex", flexDirection: "row" }}>
                    <Typography>Page</Typography>
                    <TextField
                        style={{
                            width: "2em",
                            marginLeft: "0.6em",
                            marginRight: "0.6em",
                            verticalAlign: "middle",
                            overflow: "hidden"
                        }}
                        variant="standard"
                        onChange={(event) => {
                            const val = parseInt(event.target.value);
                            if (Number.isNaN(val)
                                || numPages == null
                                || val >= numPages
                            ) return;

                            scrollToPage(val, props.documentPageRefs);
                        }}
                    >

                    </TextField>
                    <Typography>/ {numPages}</Typography>
                </div>
            </Box>
            <ButtonGroup variant="outlined" aria-label="outlined button group">
                <Button onClick={() => {
                    setZoom(zoom + 0.24);
                }}>
                    <ZoomInIcon color="primary" />
                </Button>
                <Button onClick={() => {
                    setZoom(1.384);
                }}>
                    <FitScreenIcon color="primary" />
                </Button>
                <Button onClick={() => {
                    setZoom(zoom - 0.2);
                }}>
                    <ZoomOutIcon color="primary" />
                </Button>
            </ButtonGroup>
        </Box>
        {renderDocument()}
    </Box>;
});

export default PdfDocument;
import { FunctionComponent, useContext } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AssetContext from "contexts/AssetContext";
import * as ast from "utils/AssetUtils";

interface ManagementSidebarProps {

}

const ManagementSidebar: FunctionComponent<ManagementSidebarProps> = (props): JSX.Element => {
    const assetCtx = useContext(AssetContext);
    const assetId: number | undefined = assetCtx.selectedId;
    const asset: ast.AssetProps | undefined = !assetId ? undefined :
        assetCtx.assets.get(assetId)
    const assetModel: ast.AssetModelProps | undefined = !asset ? undefined :
        assetCtx.assetModels.get(asset.modelId)
    const assetManufacturer: ast.AssetManufacturerProps | undefined = !assetModel ? undefined :
        assetCtx.assetManufacturers.get(assetModel.manufacturerId)
    const assetSubType: ast.AssetSubTypeProps | undefined = !assetModel ? undefined :
        assetCtx.assetSubTypes.get(assetModel.subTypeId)
    const assetType: ast.AssetTypeProps | undefined = !assetSubType ? undefined :
        assetCtx.assetTypes.get(assetSubType.assetTypeId)

    return (<Box sx={{ p: 3 }}>
        <Typography fontSize="medium">
            <strong>Name:</strong> {asset?.name}
            <br />
            <strong>Model:</strong> {assetModel?.name}
            <br />
            <strong>Manufacturer:</strong> {assetManufacturer?.name}
            <br />
            <strong>Subtype:</strong> {assetSubType?.name}
            <br />
            <strong>Type:</strong> {assetType?.name}
        </Typography>
    </Box>
    );
}

export default ManagementSidebar;
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { FunctionComponent, useState } from "react";

import logo from "images/percev.png";
import ValidatedPasswordForms, { validatePasswordInput } from "./ValidatedPasswordForms";
import FormGroup from "@mui/material/FormGroup";
import Button from "@mui/material/Button";
import { resetPassword } from "utils/AuthRequests";
import Typography from "@mui/material/Typography";

interface ResetPasswordPageProps {

}

const ResetPasswordPage: FunctionComponent<ResetPasswordPageProps> = (props): JSX.Element => {
    const [password, setPassword] = useState<string>("");
    const [passwordConfirm, setPasswordConfirm] = useState<string>("");
    const [isProcessing, setProcessing] = useState<boolean>(false);
    const [responseMessage, setResponseMessage] = useState<string | null>(null);

    const params = new URLSearchParams(document.location.search);
    const email = params.get("email");
    const token = params.get("token");

    function resetPasswordAction() {
        setProcessing(true);
        setResponseMessage(null);
        if (!validatePasswordInput(password, passwordConfirm)) return;
        if (email === null || token === null) return
        resetPassword(email, token, password).then(response => {
            response.text().then(res => { setResponseMessage(res); });
        }).finally(() => {
            setProcessing(false);
            window.location.href = "/";
        })
    }

    return (<div className="login-page-container">
        <Paper elevation={12} className="login-paper" style={{ height: "24em" }}>
            <Box className="frsbc" style={{ width: "100%", justifyContent: "center" }}>
                <a href="https://percevx.ai" target="_blank">
                    <img src={logo} height={64} alt="Foreman XAI by Percēv" />
                </a>
            </Box>
            {email === null || token === null
                ? <div className="login-page-form-group" style={{ margin: 2 }}>
                    Invalid request. Please contact info@percev.co if you need assistance.
                </div>
                : <FormGroup className="login-page-form-group" onKeyDown={(event) => {
                    if (event.key === 'Enter' && !event.shiftKey && !event.ctrlKey) {

                    }
                }}>
                    <ValidatedPasswordForms
                        password={password}
                        setPassword={setPassword}
                        passwordConfirm={passwordConfirm}
                        setPasswordConfirm={setPasswordConfirm}
                        formsDisabled={isProcessing}
                        onPwdFormChange={() => {
                            setResponseMessage(null);
                        }}
                    />
                    <Button
                        variant="contained"
                        fullWidth
                        disabled={!validatePasswordInput(password, passwordConfirm) || isProcessing}
                        onClick={resetPasswordAction}
                    >
                        Change password
                    </Button>
                    <Typography>
                        {responseMessage}
                    </Typography>
                </FormGroup>
            }
        </Paper>
    </div >)
}

export default ResetPasswordPage;
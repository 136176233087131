import { ChatInstance, ChatMessage } from "./ChatUtils";

import ENDPOINT from "./EndpointDefinition";
import { bearerSessionToken } from "./Session";

export async function readChatHistory(chatId: number): Promise<ChatMessage[]> {
    const response = await fetch(`${ENDPOINT}/Chat/readhistory/${chatId}`,
        {
            method: 'GET',
            mode: 'cors',
            headers: {
                'accept': 'application/json',
                "Authorization": bearerSessionToken()
            }
        }
    );
    const data = await response.json();
    return data.history;
}

export function getCurrentEndpoint(): string {
    return ENDPOINT;
}

export async function listChatInstances(): Promise<ChatInstance[]> {
    const response = await fetch(`${ENDPOINT}/Chat/list`,
        {
            method: 'GET',
            mode: 'cors',
            headers: {
                'accept': 'application/json',
                "Authorization": bearerSessionToken(),
            }
        }
    );
    const data = await response.json();
    return data;
}

export async function createChatInstance(assetId: number | null, displayName: string | null): Promise<ChatInstance> {
    const response = await fetch(`${ENDPOINT}/Chat/create` +
        `?assetId=${assetId ?? 0}` +
        `&displayName=${encodeURIComponent(displayName ?? "")}`,
        {
            method: "POST",
            mode: "cors",
            headers: {
                "accept": "text/plain",
                "Authorization": bearerSessionToken()
            }
        }
    );

    const data = await response.json();
    return data;
}


export async function createChatMessage(chatId: number, content: string): Promise<number> {

    const response = await fetch(`${ENDPOINT}/Chat/createmessage` +
        `?chatId=${chatId}` +
        `&content=${encodeURIComponent(content ?? "")}`,
        {
            method: "POST",
            mode: "cors",
            headers: {
                "accept": "*/*",
                "Authorization": bearerSessionToken()
            }
        }
    );
    return response.status;
}


export async function startPromptTree(
    chatId: number,
    architecture: string,
): Promise<number> {
    const response = await fetch(`${ENDPOINT}/Chat/prompt/${architecture}` +
        `?chatId=${chatId}`,
        {
            method: "POST",
            mode: "cors",
            headers: {
                "accept": "text/plain",
                "Authorization": bearerSessionToken()
            }
        }
    );
    return response.status;
}

export async function deleteChatInstance(
    chatId: number,
): Promise<number> {
    const response = await fetch(`${ENDPOINT}/Chat/delete` +
        `?chatId=${chatId}`,
        {
            method: "DELETE",
            mode: "cors",
            headers: {
                "accept": "*/*",
                "Authorization": bearerSessionToken()
            }
        }
    );
    return response.status;
}

export async function getUserSpending(): Promise<number> {
    const response = await fetch(`${ENDPOINT}/Chat/spending`,
        {
            method: "GET",
            mode: "cors",
            headers: {
                "accept": "text/plain",
                "Authorization": bearerSessionToken()
            }
        }
    );
    return response.json();
}
import { FunctionComponent, useContext, MouseEvent } from "react";

import List from "@mui/material/List";
import Divider from "@mui/material/Divider";


import AssetContext from "contexts/AssetContext";
import { AssetManufacturerProps, AssetModelProps, AssetProps, AssetSubTypeProps, AssetTypeProps } from "utils/AssetUtils";
import AssetEntry from "./AssetEntry";

interface AssetListProps {
    handleEdit: (id: number) => void;
    handleDelete: (id: number) => void;
}

const AssetList: FunctionComponent<AssetListProps> = (props): JSX.Element => {
    const assetCtx = useContext(AssetContext);

    function handleSelect(id: number, e: MouseEvent): void {
        assetCtx.setSelectedId(id);
    }

    return <List sx={{ p: 0 }}>
        {/* <SearchBar onChange={()=>{}}/> */}
        <Divider />
        {Array.from(assetCtx.assets).map((pair: [number, AssetProps], i: number) => {

            const model: AssetModelProps | undefined = assetCtx.assetModels.get(pair[1].modelId);
            const assetSubType: AssetSubTypeProps | undefined = model === undefined ?
                undefined :
                assetCtx.assetSubTypes.get(model.subTypeId);

            const assetType: AssetTypeProps | undefined = assetSubType === undefined ?
                undefined :
                assetCtx.assetTypes.get(assetSubType.assetTypeId);

            const manufacturer: AssetManufacturerProps | undefined = model === undefined ?
                undefined : 
                assetCtx.assetManufacturers.get(model.manufacturerId)

            return <AssetEntry
                key={i}
                asset={pair[1]}
                model={model}
                assetSubType={assetSubType}
                assetType={assetType}
                manufacturer={manufacturer}
                isSelected={assetCtx.selectedId === pair[0]}
                handleSelect={handleSelect}
                handleEdit={props.handleEdit}
                handleDelete={props.handleDelete}
            />
        })}
    </List>
}

export default AssetList;
import { FunctionComponent, MouseEvent } from "react";
import { useTheme } from "@mui/material/styles";

import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import SettingsIcon from '@mui/icons-material/Settings';
import DeleteIcon from '@mui/icons-material/Delete';
import ListItemButton from "@mui/material/ListItemButton";
import Typography from "@mui/material/Typography";

import { AssetManufacturerProps, AssetModelProps, AssetProps, AssetSubTypeProps, AssetTypeProps } from "utils/AssetUtils";

interface AssetEntryProps {
    asset: AssetProps
    model: AssetModelProps | undefined;
    assetSubType: AssetSubTypeProps | undefined;
    assetType: AssetTypeProps | undefined;
    manufacturer: AssetManufacturerProps | undefined;
    isSelected: boolean;
    handleSelect: (id: number, e: MouseEvent) => void;
    handleEdit: (id: number) => void;
    handleDelete: (id: number) => void;
}

// TODO: Clean up styles
const AssetEntry: FunctionComponent<AssetEntryProps> = (props): JSX.Element => {
    const theme = useTheme();
    const primaryColor = theme.palette.primary.main;

    return <ListItem disablePadding divider>
        <ListItemButton
            style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: props.isSelected ? "#e8eaff" : "#fff",
                borderLeft: props.isSelected
                    ? `8px ${primaryColor} solid`
                    : `0px ${primaryColor} solid`,
                transition: "border 0.1375s ease-out, background 0.1375s linear",
            }}
            onClick={(e) => {
                if (props.isSelected) return;
                props.handleSelect(props.asset.id, e);
            }}
        >
            <div
                style={{
                    width: props.isSelected ? "55%" : "90%",
                    overflowX: "visible",
                }}
            >
                <div>
                    <Typography><b>{props.asset.name}</b></Typography>
                    {
                        props.model &&
                        <Typography noWrap>
                            {props.model.name} {props.manufacturer == null ? "" : ("· " + props.manufacturer.name)}
                        </Typography>
                    }
                </div>
                <Typography fontSize="small" noWrap>
                    {props.assetType?.name ?? ""} {props.assetSubType == null ? "" : ("· " + props.assetSubType.name)}
                </Typography>
            </div>

            <div className="frsbc" style={{
                opacity: props.isSelected ? "100%" : "0%",
                transition: "opacity 0.1375s linear",
                gap: "0.375em",
            }}>
                <IconButton
                    color="primary"
                    disabled={!props.isSelected}
                    style={{
                        border: "1px solid #2925628f",
                        borderRadius: 8,
                    }}
                    onClick={(e) => {
                        e.stopPropagation();
                        props.handleEdit(props.asset.id);
                    }}
                >
                    <SettingsIcon />
                </IconButton>
                <IconButton
                    color="error"
                    disabled={!props.isSelected}
                    style={{
                        border: "1px solid #d88",
                        borderRadius: 8,
                    }}
                    onClick={(e) => {
                        e.stopPropagation();
                        props.handleDelete(props.asset.id);
                    }}
                >
                    <DeleteIcon />
                </IconButton>
            </div>
        </ListItemButton>
    </ListItem>
}

export default AssetEntry;
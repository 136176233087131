export interface DocumentDataProps {
    id: number;
    name: string;
    typeId: number;
    isPublic: boolean;
    isUploading: boolean;
    hasBeenProcessed: boolean;
    assetDocuments: number[]
}

export function createDocumentProps(props: {
    DocumentID: number,
    DocumentName: string,
    DocumentTypeID: number,
    IsPublic: boolean,
    IsUploading: boolean,
    HasBeenProcessed: boolean,
    AssetDocuments: { AssetModelID: number }[]
}): DocumentDataProps {
    return {
        id: props.DocumentID,
        name: props.DocumentName,
        typeId: props.DocumentTypeID,
        isPublic: props.IsPublic,
        isUploading: props.IsUploading,
        hasBeenProcessed: props.HasBeenProcessed,
        assetDocuments: props.AssetDocuments.map(asset => asset.AssetModelID),
    };
}

import { associateWith } from "./hashmapUtils";

export interface AssetTypeProps {
    id: number;
    name: string;
    isPublic: boolean;
}

export interface AssetSubTypeProps {
    id: number;
    name: string;
    assetTypeId: number;
    isPublic: boolean;
}

export interface AssetManufacturerProps {
    id: number;
    name: string;
    isPublic: boolean;
}

export interface AssetModelProps {
    id: number;
    name: string;
    typeId: number;
    subTypeId: number;
    manufacturerId: number;
    isPublic: boolean;
}

export interface AssetProps {
    id: number;
    name: string;
    modelId: number;
}

export type AssetCategorizationMap = Map<AssetTypeProps | undefined,
    Map<AssetSubTypeProps | undefined, AssetModelProps[]>
>

// == Factories ==
export function createAssetType(props: {
    AssetTypeID: number,
    AssetTypeName: string,
    IsPublic: boolean
}): AssetTypeProps {
    return {
        id: props.AssetTypeID,
        name: props.AssetTypeName,
        isPublic: props.IsPublic
    };
}

export function createAssetSubType(props: {
    AssetSubTypeID: number;
    AssetSubTypeName: string;
    AssetTypeID: number;
    // AssetTypeName: string;
    IsPublic: boolean;
}): AssetSubTypeProps {
    return {
        id: props.AssetSubTypeID,
        name: props.AssetSubTypeName,
        assetTypeId: props.AssetTypeID,
        isPublic: props.IsPublic
    };
}

export function createAssetManufacturer(props: {
    AssetManufacturerID: number;
    AssetManufacturerName: string;
    IsPublic: boolean;
}): AssetManufacturerProps {
    return {
        id: props.AssetManufacturerID,
        name: props.AssetManufacturerName,
        isPublic: props.IsPublic
    }
}

export function createAssetModel(props: {
    AssetModelID: number;
    AssetModelName: string;
    AssetTypeID: number;
    // AssetTypeName: string;
    AssetSubTypeID: number;
    // AssetSubTypeName: string;
    AssetManufacturerID: number;
    // AssetManufacturerName: string;
    IsPublic: boolean;
}): AssetModelProps {
    return {
        id: props.AssetModelID,
        name: props.AssetModelName,
        typeId: props.AssetTypeID,
        subTypeId: props.AssetSubTypeID,
        manufacturerId: props.AssetManufacturerID,
        isPublic: props.IsPublic
    }
}

export function createAsset(props: {
    AssetID: number;
    AssetName: string;
    AssetModelID: number;
}): AssetProps {
    return {
        id: props.AssetID,
        name: props.AssetName,
        modelId: props.AssetModelID
    }
}


export function categorizeModels(
    models: Map<number, AssetModelProps>,
    subtypes: Map<number, AssetSubTypeProps>,
    types: Map<number, AssetTypeProps>
): AssetCategorizationMap {
    const subtypesMap: Map<AssetSubTypeProps | undefined, AssetModelProps[]> = associateWith(
        Array.from(subtypes.values()),
        () => [] as AssetModelProps[]
    )
    subtypesMap.set(undefined, [])

    const result: AssetCategorizationMap = associateWith(
        Array.from(types.values()),
        () => {
            const emptyMap = new Map<AssetSubTypeProps | undefined, AssetModelProps[]>();
            emptyMap.set(undefined, [] as AssetModelProps[]);
            return emptyMap;
        }
    );
    result.set(undefined, new Map());

    for (const [_, model] of models) {
        const st: AssetSubTypeProps | undefined = subtypes.get(model.subTypeId);
        const genericTypeMap: Map<AssetSubTypeProps | undefined, AssetModelProps[]> | undefined =
            result.get(types.get(model.typeId))
        if (st === undefined && genericTypeMap !== undefined) {
            const genericModelList: AssetModelProps[] | undefined = genericTypeMap.get(undefined)
            if (genericModelList !== undefined) {
                genericModelList.push(model)
                continue
            }  // If these two conditions didn't satisfy, push as normal below
        }
        subtypesMap.get(st)!.push(model)
        // Null coersion is safe because we have `undefined` as a key
    }

    for (const [st, models] of subtypesMap) {
        const t: AssetTypeProps | undefined = st ? types.get(st.assetTypeId) : undefined;
        const stMap: Map<AssetSubTypeProps | undefined, AssetModelProps[]> = result.get(t)!
        stMap.set(st, models);
    }
    return result;
}
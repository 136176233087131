import "./styles.css";
import { Fragment, FunctionComponent, useContext, useEffect, useState } from "react";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import ListItemButton from "@mui/material/ListItemButton";
import DocumentEntry from "./DocumentEntry";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";

import NoteAddIcon from '@mui/icons-material/NoteAdd';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TopicIcon from '@mui/icons-material/Topic';
import RefreshIcon from '@mui/icons-material/Refresh';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';

import DocumentContext from "contexts/DocumentContext";
import AppContext from "contexts/AppContext";
import { getAllDocuments, getAssociatedDocuments } from "utils/DocumentBackendRequests";
import { DocumentDataProps } from "utils/DocumentUtils";
import { ThemedAccordion, ThemedAccordionDetails, ThemedAccordionSummary } from "../LeftSidebar";
import SearchBar from "../AssetAccordion/AssetList/SearchBar";
import { DocumentCreationModal } from "./DocumentCreation";
import { cleanAndLowercase } from "utils/MiscUtils";

interface DocumentAccordionProps {

}

const DocumentAccordion: FunctionComponent<DocumentAccordionProps> = (props): JSX.Element => {
    const ctx = useContext(AppContext);
    const docCtx = useContext(DocumentContext);

    const [docCreationModalOpen, setDocCreationModalOpen] = useState<boolean>(false);
    const [associatedDocList, setAssociatedDocList] = useState<number[]>([]);
    const [displayAllDocuments, setDisplayAllDocuments] = useState<boolean>(false);
    const listedDocs: number[] = displayAllDocuments ? Array.from(docCtx.documentMap.keys()) as number[] : associatedDocList

    const [searchPattern, setSearchPattern] = useState<string>("");
    const processedPattern = cleanAndLowercase(searchPattern);

    useEffect(() => {
        updateDocumentList();
    }, [])

    useEffect(() => {
        if (ctx.chatId !== undefined) {
            updateAssociatedDocuments(ctx.chatId)
        }
    }, [ctx.chatId])

    function matchAgainstSearch(s: string): boolean {
        if (searchPattern.length === 0) return true;
        else return cleanAndLowercase(s).includes(processedPattern);
    }

    function onDocumentEntrySelection(id: number) {
        docCtx.setDocumentId(id);
        docCtx.setTabValue(1);
    }
    function refreshAll() {
        updateDocumentList();
        updateAssociatedDocuments(ctx.chatId);
    }

    function openDocumentCreationModal() {
        setDocCreationModalOpen(true);
        refreshAll();
    }

    function closeDocumentCreationModal() {
        setDocCreationModalOpen(false);
        setTimeout(refreshAll, 846.8650);
    }

    function updateDocumentList() {
        getAllDocuments().then((result) => {
            docCtx.setDocumentMap(result);
        }).catch(error => {
            console.log("DocumentAccordion.tsx: Error in getting all documents")
            console.log(error)
        })
    }

    function updateAssociatedDocuments(chatId: number | undefined) {
        if (chatId === undefined) return;
        getAssociatedDocuments(chatId).then((result) => {
            setAssociatedDocList(result);
        }).catch(error => {
            console.log("DocumentAccordion.tsx: Error in getting associated documents")
            console.log(error)
        });
    }



    return <>
        <DocumentCreationModal
            chatId={ctx.chatId}
            alreadyAssociated={associatedDocList}
            handleClose={closeDocumentCreationModal}
            isOpen={docCreationModalOpen}
            refreshAll={refreshAll}
        />
        <ThemedAccordion>
            <ThemedAccordionSummary expandIcon={<ExpandMoreIcon />} className="frsbc">
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    flexGrow: 1
                }}>
                    <TopicIcon color="primary" />&ensp;<Typography>Documents</Typography>
                </div>
                <Typography color="text.secondary" noWrap>
                    {associatedDocList.length} in use &ensp;
                </Typography>
            </ThemedAccordionSummary>
            <ThemedAccordionDetails sx={{ p: 0 }}>
                <List sx={{ pt: 0 }}>
                    <ListItem key={31 /*Doesn't matter*/} disablePadding divider style={{
                        display: "flex",
                        padding: "6px",
                        gap: "6px",
                        justifyContent: "space-between",
                    }}>
                        <ListItemButton
                            onClick={openDocumentCreationModal}
                            style={{
                                width: "100%",
                                border: "1px #ddd solid",
                                borderRadius: "4px",
                                backgroundColor: "#f0f0f0"
                            }}
                        >
                            <NoteAddIcon />&ensp;
                            <strong>Add</strong>
                        </ListItemButton>
                        <ListItemButton
                            onClick={() => { setDisplayAllDocuments(!displayAllDocuments); }}
                            style={{
                                width: "100%",
                                border: "1px #ddd solid",
                                borderRadius: "4px",
                                backgroundColor: displayAllDocuments ? "#d4d4d4" : "#f0f0f0"
                            }}
                        >
                            <LibraryBooksIcon />&ensp;
                            <strong>Library</strong>
                        </ListItemButton>

                    </ListItem>
                    <SearchBar onChange={(e) => { setSearchPattern(e.target.value) }} />
                    <Divider />
                    <div className="frsbc">
                        <Typography sx={{ ml: "1em" }} color="text.secondary">
                            {displayAllDocuments ? "All documents:" : "Documents in this chat:"}
                        </Typography>
                        <IconButton size="small" onClick={refreshAll}>
                            <RefreshIcon fontSize="small" />
                        </IconButton>
                    </div>
                    {listedDocs.map((documentId, i) => {
                        const doc: DocumentDataProps | undefined = docCtx.documentMap.get(documentId);
                        if (doc === undefined || !matchAgainstSearch(doc.name)) return null;
                        return <DocumentEntry
                            isSelected={docCtx.documentId == documentId}
                            key={i}
                            doc={doc}
                            onEntryClick={onDocumentEntrySelection}
                            refreshAll={refreshAll}
                            allowEdit
                        />;
                    })}
                </List>
            </ThemedAccordionDetails>
        </ThemedAccordion>
    </>;
}

export default DocumentAccordion;
export function associate<E, K, V>(arr: E[], key: (el: E) => K, value: (el: E) => V): Map<K, V> {
    const map = new Map<K, V>();
    for (const item of arr) {
        map.set(key(item), value(item));
    }
    return map
}

export function associateBy<K, V>(arr: V[], key: (v: V) => K): Map<K, V> {
    const map = new Map<K, V>();
    for (const item of arr) {
        map.set(key(item), item);
    }
    return map
}

export function associateWith<K, V>(arr: K[], value: (k: K) => V): Map<K, V> {
    const map = new Map<K, V>();
    for (const item of arr) {
        map.set(item, value(item));
    }
    return map;
}

export function associateById<V extends { id: number }>(arr: V[]): Map<number, V> {
    const map = new Map<number, V>();
    for (const item of arr) {
        map.set(item.id, item);
    }
    return map;
}

import { createContext } from "react";  
import * as ast from "utils/AssetUtils";

export interface AssetContextProps {
    assetTypes: Map<number, ast.AssetTypeProps>;
    assetSubTypes: Map<number, ast.AssetSubTypeProps>;
    assetManufacturers: Map<number, ast.AssetManufacturerProps>;
    assetModels: Map<number, ast.AssetModelProps>;
    assets: Map<number, ast.AssetProps>;
    setAssetTypes: (arg0: Map<number, ast.AssetTypeProps>) => void;
    setAssetSubTypes: (arg0: Map<number, ast.AssetSubTypeProps>) => void;
    setAssetManufacturers: (arg0: Map<number, ast.AssetManufacturerProps>) => void;
    setAssetModels: (arg0: Map<number, ast.AssetModelProps>) => void;
    setAssets: (arg0: Map<number, ast.AssetProps>) => void;
    selectedId: number | undefined;
    setSelectedId: (arg0: number | undefined) => void;
}

const AssetContext = createContext<AssetContextProps>({
    assetTypes: new Map<number, ast.AssetTypeProps>(),
    assetSubTypes: new Map<number, ast.AssetSubTypeProps>(),
    assetManufacturers: new Map<number, ast.AssetManufacturerProps>(),
    assetModels: new Map<number, ast.AssetModelProps>(),
    assets: new Map<number, ast.AssetProps>(),
    setAssetTypes: () => { },
    setAssetSubTypes: () => { },
    setAssetManufacturers: () => { },
    setAssetModels: () => { },
    setAssets: () => { },
    selectedId: undefined,
    setSelectedId: () => { },
});

export default AssetContext;
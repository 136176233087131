import { FunctionComponent, useContext, useState } from "react";

import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';

import logo from "images/percev.png";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import { getUserSpending } from "utils/ChatBackendRequests";
import AppContext from "contexts/AppContext";

interface BrandingCardProps {
    logoutAction: () => void;
}

const BrandingCard: FunctionComponent<BrandingCardProps> = (props): JSX.Element => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return <div className="branding-card">
        {/* <div style={{
            width: "fit-content",

            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        }}> */}
        {/* <IconButton>
                <MenuIcon color="primary" />
            </IconButton> */}
        <a href="https://percevx.ai" target="_blank">
            <img src={logo} height={45} alt="Foreman XAI by Percēv" />
        </a>
        {/* </div> */}

        <div>
            <IconButton
                size="large"
                color="inherit"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}>
                <AccountCircleIcon color="primary"
                />
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {/* <MenuItem onClick={handleClose}>Account</MenuItem>
                <MenuItem onClick={handleClose}>Usage</MenuItem>
                <Divider /> */}
                <MenuItem onClick={props.logoutAction}>Log out</MenuItem>
            </Menu>
        </div>
    </div>
}

export default BrandingCard;

import { styled } from "@mui/material/styles";

import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

const FolderAccordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    // border: `1px solid #c5c5c5`,
    '&:before': {
        display: 'none',
    },
}));

const FolderAccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary {...props} />
))(({ theme }) => ({
    minHeight: "32px",
    ".MuiAccordionSummary-content": {
        height: "100%",
        width: "100%",
        margin: 0,
    }
}));

const FolderAccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: 0,
    paddingLeft: "0.25em",
    // borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export { FolderAccordion, FolderAccordionDetails, FolderAccordionSummary };
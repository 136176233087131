import "./styles.css";

import { FunctionComponent, useContext, useEffect, useState } from "react";
import { ThemedAccordion, ThemedAccordionDetails, ThemedAccordionSummary } from "../LeftSidebar";

import Typography from "@mui/material/Typography";

import AssetContext, { AssetContextProps } from "contexts/AssetContext";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";

import FactoryIcon from '@mui/icons-material/Factory';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddBoxIcon from '@mui/icons-material/AddBox';
import EngineeringIcon from '@mui/icons-material/Engineering';
import { AssetList } from "./AssetList";
import Divider from "@mui/material/Divider";
import { getDescriptorLists, getAssetList, createNewAsset, updateAsset, deleteAsset } from "utils/AssetBackendRequests";
import { associateById } from "utils/hashmapUtils";
import AssetCreationModal from "./AssetCreationModal";
import Snackbar from "@mui/material/Snackbar";

interface AssetAccordionProps {

}

const AssetAccordion: FunctionComponent<AssetAccordionProps> = (props): JSX.Element => {
    const assetCtx: AssetContextProps = useContext(AssetContext);

    const [creationModalOpen, setCreationModalOpen] = useState<boolean>(false);
    const [editingAssetId, setEditingAssetId] = useState<number | null>(null);

    const [assetUpdateSnackbarOpen, setAssetUpdateSnackbarOpen] = useState<boolean>(false);

    function refreshAssetData(callback?: () => void) {
        getDescriptorLists().then((result) => {
            if (result === undefined) return;
            assetCtx.setAssetTypes(associateById(result.assetTypes));
            assetCtx.setAssetSubTypes(associateById(result.assetSubTypes));
            assetCtx.setAssetManufacturers(associateById(result.assetManufacturers));
            assetCtx.setAssetModels(associateById(result.assetModels));
        }).then(() => {
            if (callback !== undefined) callback();
        });

        getAssetList().then((result) => {
            if (result === undefined) return;
            assetCtx.setAssets(associateById(result));
        });
    }


    useEffect(() => {
        refreshAssetData();
    }, [])

    function closeAssetCreationModal() {
        setCreationModalOpen(false);
        refreshAssetData();
    }

    function handleAssetCreationSubmission(displayName: string, model: number, edittedId: number | null) {
        if (edittedId === null) {
            createNewAsset(displayName, model).then(() => {
                closeAssetCreationModal();
                setAssetUpdateSnackbarOpen(true);
            });
        } else {
            updateAsset(edittedId, displayName).then(() => {
                closeAssetCreationModal();
                setAssetUpdateSnackbarOpen(true);
            });
        }
    }

    function openAssetCreationModal() {
        setEditingAssetId(null);
        setCreationModalOpen(true);
        refreshAssetData();
    }

    function handleOpenEdit(id: number) {
        setEditingAssetId(id);
        setCreationModalOpen(true);
        refreshAssetData();
    }

    function handleDelete(id: number) {
        deleteAsset(id).then(() => {
            closeAssetCreationModal();
            setAssetUpdateSnackbarOpen(true);
        });
    }

    return <>
        <Snackbar
            open={assetUpdateSnackbarOpen}
            autoHideDuration={8000}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
            }}
            onClose={() => { setAssetUpdateSnackbarOpen(false); }}
            message="Updated asset"
        />

        <AssetCreationModal
            isOpen={creationModalOpen}
            handleClose={closeAssetCreationModal}
            handleSubmit={handleAssetCreationSubmission}
            refreshAssetData={refreshAssetData}
            editingAssetId={editingAssetId}
        />

        <ThemedAccordion>
            <ThemedAccordionSummary expandIcon={<ExpandMoreIcon />}>
                <FactoryIcon color="primary" />&ensp;<Typography>Assets</Typography>
            </ThemedAccordionSummary>
            <ThemedAccordionDetails sx={{ p: 0 }}>
                <List sx={{ pt: 0 }}>
                    <ListItem disablePadding style={{
                        display: "flex",
                        padding: "6px",
                        gap: "6px",
                        justifyContent: "space-between",
                    }}>
                        <ListItemButton
                            onClick={openAssetCreationModal}
                            style={{
                                border: "1px #ddd solid",
                                borderRadius: "4px",
                                backgroundColor: "#f0f0f0"
                            }}
                        >
                            <AddBoxIcon />&ensp;
                            <strong>Create</strong>
                        </ListItemButton>
                        <ListItemButton
                            disabled
                            onClick={() => { }}
                            style={{
                                border: "1px #ddd solid",
                                borderRadius: "4px",
                                backgroundColor: "#f0f0f0"
                            }}
                        >
                            <EngineeringIcon />&ensp;
                            <strong>Manage</strong>
                        </ListItemButton>
                    </ListItem>
                    <Divider variant="middle" />
                    <AssetList handleEdit={handleOpenEdit} handleDelete={handleDelete} />
                </List>
            </ThemedAccordionDetails>
        </ThemedAccordion>
    </>
}

export default AssetAccordion;
import { FunctionComponent, useState } from "react";

import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import CircularProgress from "@mui/material/CircularProgress";

import ReplayIcon from '@mui/icons-material/Replay';
import SendIcon from "@mui/icons-material/Send";

interface ChatInputBoxProps {
    disabled: boolean
    isProcessing: boolean;   // prompt is being processed
    handleChatMessageSubmit: (arg0: string) => void;
    quotaLimit: boolean;
}

const ChatInputBox: FunctionComponent<ChatInputBoxProps> = (props): JSX.Element => {
    const [chatBoxContent, setChatBoxContent] = useState<string>("");
    const disableInput: boolean = props.disabled || props.isProcessing || props.quotaLimit

    return <div style={{ display: "flex" }}>
        <TextField
            multiline
            value={chatBoxContent}
            inputProps={{ maxLength: 8192 }}
            onChange={(event) => {
                setChatBoxContent(event.target.value)
            }}
            onKeyDown={(event) => {
                if (event.key === 'Enter' && !event.shiftKey && !event.ctrlKey) {
                    event.stopPropagation();
                    event.preventDefault();
                    props.handleChatMessageSubmit(chatBoxContent);
                    setChatBoxContent("");
                }
            }}
            maxRows={7}
            variant="standard"
            disabled={disableInput}
            sx={{ ml: 1, mr: 1, flex: 1 }}
            placeholder="Send a message"
        />
        {/* <Divider orientation="vertical" /> */}
        <div className="frsbc">
            <Tooltip title={
                <div>
                    Regenerate (Disabled feature)
                </div>
            } placement="top" color="disabled">
                <ReplayIcon />   
            </Tooltip>
            <IconButton
                color="primary"
                sx={{ p: "10px" }}
                onClick={() => {
                    props.handleChatMessageSubmit(chatBoxContent);
                    setChatBoxContent("");
                }}
                disabled={disableInput || chatBoxContent.length === 0}
            >
                {props.isProcessing ? <CircularProgress size={16} /> :
                    <SendIcon />
                }
            </IconButton>
        </div>
    </div>
}

export default ChatInputBox;
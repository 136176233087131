import { FunctionComponent, useState, useContext, Fragment } from "react";


import List from "@mui/material/List";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

import RefreshIcon from '@mui/icons-material/Refresh';

import SearchBar from "components/LeftSidebar/AssetAccordion/AssetList/SearchBar";
import DocumentContext from "contexts/DocumentContext";
import DocumentEntry from "../DocumentEntry";
import { associateDocument } from "utils/DocumentBackendRequests";
import Typography from "@mui/material/Typography";
import { cleanAndLowercase } from "utils/MiscUtils";

interface ChatLibraryTab {
    chatId: number | undefined;
    alreadyAssociated: number[];
    handleClose: () => void;
    refreshAll: () => void;
}

const ChatLibraryTab: FunctionComponent<ChatLibraryTab> = (props): JSX.Element => {
    const [selectedDocs, setSelectedDocs] = useState<Set<number>>(new Set());
    const docCtx = useContext(DocumentContext);

    const [searchPattern, setSearchPattern] = useState<string>("");
    const processedPattern = cleanAndLowercase(searchPattern);

    function matchAgainstSearch(s: string): boolean {
        if (searchPattern.length === 0) return true;
        else return cleanAndLowercase(s).includes(processedPattern);
    }

    function handleSubmit() {
        if (props.chatId === undefined) return;
        (async (chatId: number) => {
            for (const docId of selectedDocs) {
                await associateDocument(docId, chatId);
            }
        })(props.chatId);

        setSelectedDocs(new Set());
        props.handleClose();
    }

    function toggleSelection(el: number) {
        const newSelectedDocs = new Set(selectedDocs);
        if (selectedDocs.has(el)) {
            newSelectedDocs.delete(el);
        } else {
            newSelectedDocs.add(el);
        }

        setSelectedDocs(newSelectedDocs);
    }
    // TODO: Might break scroll
    return <>
        <List style={{ padding: 0, height: "100%" }}>
            <div className="frsbc">
                <SearchBar onChange={(e) => { setSearchPattern(e.target.value) }} compact />
                <IconButton size="small" onClick={props.refreshAll} style={{
                    borderRadius: "4px"

                }}>
                    <Typography sx={{ mr: 0.375 }}>
                        Refresh documents
                    </Typography>
                    <RefreshIcon fontSize="small" />
                </IconButton>
            </div>
            <div style={{
                height: "21.125em",
                overflowY: "scroll"
            }}>
                {
                    Array.from(docCtx.documentMap).map(([documentId, documentProps], i) => {
                        if (!matchAgainstSearch(documentProps.name)
                            || props.alreadyAssociated.includes(documentId)) return null;
                        return <DocumentEntry
                            isSelected={selectedDocs.has(documentId)}
                            key={i}
                            doc={documentProps}
                            onEntryClick={toggleSelection}
                            refreshAll={props.refreshAll}
                        />;
                    })
                }
            </div>
        </List>
        <div className="doc-creation-buttons-container">
            <Button variant="outlined" onClick={props.handleClose}>
                Cancel
            </Button>
            <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={handleSubmit}
            >
                Add to chat
            </Button>
        </div>
    </>
}

export default ChatLibraryTab;
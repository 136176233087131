import { DocumentDataProps, createDocumentProps } from "./DocumentUtils";
import ENDPOINT from "./EndpointDefinition";
import { bearerSessionToken } from "./Session";
import { associateById } from "./hashmapUtils";

export async function getDocumentUrl(
    documentId: number
): Promise<string | null> {
    const response = await fetch(`${ENDPOINT}/Document/source/${documentId}`, {
        method: "GET",
        mode: "cors",
        headers: {
            "accept": "text/plain",
            "Authorization": bearerSessionToken()
        },
    });
    return response.text();
}

export async function getAssociatedDocuments(chatId: number): Promise<number[]> {
    const response = await fetch(`${ENDPOINT}/Chat/documents/${chatId}`, {
        method: "GET",
        mode: "cors",
        headers: {
            "accept": "text/plain",
            "Authorization": bearerSessionToken()
        },
    });
    return response.json();
}

export async function processDocument(documentId: number): Promise<number> {
    const response = await fetch(`${ENDPOINT}/Document/process` +
        `?id=${documentId}`,
        {
            method: "POST",
            mode: "cors",
            headers: {
                "accept": "text/plain",
                "Authorization": bearerSessionToken()
            },
        }
    );
    return response.status;
}

export async function getAllDocuments(): Promise<Map<number, DocumentDataProps>> {
    const response = await fetch(`${ENDPOINT}/Document/list`, {
        method: "GET",
        mode: "cors",
        headers: {
            "accept": "application/json",
            "Authorization": bearerSessionToken()
        },
    });
    const data = await response.json();
    return associateById<DocumentDataProps>(data.map(createDocumentProps));
}

export async function associateDocument(documentId: number, chatId: number): Promise<number> {
    const response = await fetch(`${ENDPOINT}/Document/associate` +
        `?documentId=${documentId}` +
        `&chatId=${chatId}`,
        {
            method: "POST",
            mode: "cors",
            headers: {
                "accept": "*/*",
                "Authorization": bearerSessionToken()
            }
        }
    );
    return response.status;
}


export async function uploadFile(
    file: File,
    displayName: string,
    typeId: number,
    assetModelId: number | null,
    isPublic: boolean
): Promise<number> {
    const form = new FormData();
    form.append("file", file);

    const response = await fetch(`${ENDPOINT}/Document/document` +
        `?displayName=${encodeURIComponent(displayName)}` +
        `&documentTypeId=${typeId}` +
        (assetModelId === null ? "" : `&assetModelId=${assetModelId}`) +
        `&isPublic=${isPublic}`,
        {
            method: "POST",
            body: form,
            mode: "cors",
            headers: {
                "accept": "text/plain",
                "Authorization": bearerSessionToken()
                // "Content-Type": "multipart/form-data"
            }
        }
    );
    return response.status;
}

export async function updateDocument(
    id: number,
    name: string
): Promise<number> {
    // 'http://localhost:5000/api/Document/document?id=52&documentTypeId=1&displayName=name%20edit%20test&isPublic=false'
    const response = await fetch(`${ENDPOINT}/Document/document` +
        `?id=${id}` + 
        `&documentTypeId=1` + 
        `&displayName=${encodeURIComponent(name)}` +
        `&isPublic=${false}`,
        {
            method: "PUT",
            mode: "cors",
            headers: {
                "accept": "text/plain",
                "Authorization": bearerSessionToken()
            },
        }
    );
    return response.status;
}


export async function deleteFile(
    id: number,
): Promise<number> {
    const response = await fetch(`${ENDPOINT}/Document/document` +
        `?id=${id}`,
        {
            method: "DELETE",
            mode: "cors",
            headers: {
                "accept": "text/plain",
                "Authorization": bearerSessionToken()
            },
        }
    );
    return response.status;
}

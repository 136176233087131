import MenuItem from "@mui/material/MenuItem";
import { FunctionComponent, useState } from "react";
import { AssetModelProps } from "utils/AssetUtils";
import VisibilityButton from "./VisibilityButton";
import Typography from "@mui/material/Typography";

interface AssetModelEntryProps {
    model: AssetModelProps;
    manufacturerName: string | undefined;
    isSelected: boolean;
    setModel: (arg0: number) => void;
}

const AssetModelEntry: FunctionComponent<AssetModelEntryProps> = (props): JSX.Element => {
    return <MenuItem
        style={{
            padding: 0,
            marginLeft: "2.05em",
            background: props.isSelected ? "#e8eaff" : "#fff",
            borderLeft: props.isSelected
                ? `8px #292562 solid`
                : `0px #292562 solid`,
            transition: "border 0.1375s ease-out, background 0.1375s linear",
        }}
        value={props.model.id}
        onClick={(e) => {
            e.stopPropagation();
            props.setModel(props.model.id);
        }}
    >
        <div className="frsbc" style={{
            paddingLeft: "0.8812584em",
            marginTop: "2px",
            marginBottom: "2px",
            borderLeft: "1px solid #ddd",
        }}>
            <div style={{ marginRight: "0.175em" }}>
                <VisibilityButton
                    isPublicShared={false}
                    isPublicVisible={props.model.isPublic}
                    fontSize="small"
                />
            </div>
            <Typography style={{
                width: "100%",
                overflowX: "visible",
            }} noWrap>
                <strong>{props.model.name}</strong>
                {props.manufacturerName === undefined ? "" : <>
                    &nbsp;&#xB7;&nbsp;
                    {props.manufacturerName}
                </>}
            </Typography>
        </div>
    </MenuItem>;
}

export default AssetModelEntry;
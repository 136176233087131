import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { FunctionComponent } from "react";

// Function to check if a password is at least 6 characters long.
function pwdLengthRequirement(password: string): boolean {
    return password.length >= 6;
}

// Function to check if a password contains at least one non-alphanumeric character.
const NON_ALPHANUMERIC_REGEX = /[^\w\s]/
function pwdRequiresNonAlphanumeric(password: string): boolean {
    return NON_ALPHANUMERIC_REGEX.test(password);
}

// Function to check if a password contains at least one digit ('0'-'9').
const DIGIT_REGEX = /\d/
function pwdRequiresDigit(password: string): boolean {
    return DIGIT_REGEX.test(password);
}

// Function to check if a password contains at least one uppercase letter ('A'-'Z').
const UPPERCASE_REGEX = /[A-Z]/
function pwdRequiresUpper(password: string): boolean {
    return UPPERCASE_REGEX.test(password);
}

export function validatePasswordInput(password: string, passwordConfirm: string) {
    return passwordConfirm == password
        && pwdLengthRequirement(password)
        && pwdRequiresNonAlphanumeric(password)
        && pwdRequiresDigit(password)
        && pwdRequiresUpper(password);
}

interface ValidatedPasswordFormsProps {
    password: string,
    setPassword: (arg0: string) => void;
    passwordConfirm: string,
    setPasswordConfirm: (arg0: string) => void;
    formsDisabled: boolean;
    onPwdFormChange?: () => void;
}


const ValidatedPasswordForms: FunctionComponent<ValidatedPasswordFormsProps> = (props): JSX.Element => {
    const checkPwdLengthRequirement = pwdLengthRequirement(props.password);
    const checkPwdRequiresNonAlphanumeric = pwdRequiresNonAlphanumeric(props.password);
    const checkPwdRequiresDigit = pwdRequiresDigit(props.password);
    const checkPwdRequiresUpper = pwdRequiresUpper(props.password);
    const checkConfirmation = props.passwordConfirm == props.password;
    const allChecks = checkConfirmation
        && checkPwdLengthRequirement
        && checkPwdRequiresNonAlphanumeric
        && checkPwdRequiresDigit
        && checkPwdRequiresUpper;

    return (<><Box className="frsbc" style={{ gap: "1em" }}>
        <FormControl fullWidth>
            <TextField
                required
                error={props.password.length > 0 && !allChecks}
                label="Password"
                variant="outlined"
                type="password"
                disabled={props.formsDisabled}
                value={props.password}
                inputProps={{ maxLength: 384 }}
                onChange={(e) => {
                    if (props.onPwdFormChange !== undefined) props.onPwdFormChange()
                    props.setPassword(e.target.value)
                }}
            />
        </FormControl>
        <FormControl fullWidth>
            <TextField
                error={!checkConfirmation}
                required
                label="Confirm password"
                variant="outlined"
                type="password"
                disabled={props.formsDisabled}
                value={props.passwordConfirm}
                inputProps={{ maxLength: 384 }}
                onChange={(e) => {
                    if (props.onPwdFormChange !== undefined) props.onPwdFormChange()
                    props.setPasswordConfirm(e.target.value)
                }}
            />
        </FormControl>
    </Box>
        <Box>
            <ul style={{
                margin: 0,
                paddingLeft: "1em",
                color: "#c60000",
                opacity: "80%"
            }}>
                {checkPwdLengthRequirement || <li> Longer than 6 characters</li>}
                {checkPwdRequiresNonAlphanumeric || <li> Include at least one non-alphanumeric character </li>}
                {checkPwdRequiresDigit || <li> Include at least one digit (0-9)</li>}
                {checkPwdRequiresUpper || <li> Include at least one uppercase letter (A-Z)</li>}
                {checkConfirmation || <li>Passwords do not match</li>}
            </ul>
        </Box></>);
}

export default ValidatedPasswordForms;
import { FunctionComponent } from "react";

import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";

import EmbeddedCitation from "./EmbeddedCitation";
import { messageIsUserFacing } from "./Chat";
import { ChatMessage } from "utils/ChatUtils";

import HumanAvatar from "images/avatars/human.png";
import ForemanPurpleAvatar from "images/avatars/foreman-purple.png";
import LibrarianAvatar from "images/avatars/librarian.png";
import AgentTriangleAvatar from "images/avatars/agent-triangle.png";
import SystemAvatar from "images/avatars/system.png";

function getAvatarImage(message: ChatMessage) {
    if (message.UserID != null) return HumanAvatar;
    if (message.IsInternal || message.AgentID == null) return SystemAvatar;
    switch (message.AgentID) {  // TODO: !!!!!!!!!! HARD CODED AGENT IDS !!!!!!!!!!!
        case 123456000:
            return ForemanPurpleAvatar;
        case 123456001:
        case 123456002:
        case 123456003:
        case 123456004:
            return AgentTriangleAvatar;
        case 123456005:
            return LibrarianAvatar;
        default:
            return AgentTriangleAvatar;
    }
}

// const CITATION_PATTERN_REGEX: RegExp = /\|(\d+:)?(\d+).*\|/g;
const CITATION_PATTERN_REGEX: RegExp = /[\|.*]?(\d+):(\d+)[.*\|]?/g;

function injectCitations(content: string): JSX.Element[] {
    const lines: string[] = content.split("\n")
    return lines.map((line: string, i: number) => {
        if (line.trim().length === 0) return <div style={{ margin: "0.75em 0em"}} key={i}></div>;

        const citationMatches: { document_id: number | undefined, section_id: number }[] = [];
        for (const match of line.matchAll(CITATION_PATTERN_REGEX)) {
            if (match[1] === undefined) {
                citationMatches.push({
                    document_id: undefined,
                    section_id: parseInt(match[2])
                })
            } else {
                citationMatches.push({
                    document_id: parseInt(match[1].replace(":", "")),
                    section_id: parseInt(match[2]),
                })
            }
        }
        const content: string = line.replace(CITATION_PATTERN_REGEX, "").replace("[Foreman response]", "");
        return <div key={i}>
            {
                content &&
                <Typography>{content}</Typography>
            }
            {citationMatches.slice(0, 4).map((c, k) => <div key={k + i * 1279}>
                <EmbeddedCitation document_id={c.document_id} section_id={c.section_id} />
            </div>)}
        </div>
    });
}

interface ChatMessageEntryProps {
    message: ChatMessage
}

const ChatMessageEntry: FunctionComponent<ChatMessageEntryProps> = ({ message }): JSX.Element => {
    return (<>
        <Divider variant="middle" />
        <ListItem alignItems="flex-start">
            <ListItemAvatar sx={{ mt: 0.5 }}>{
                <Avatar sx={{ height: 48, width: 48 }} src={getAvatarImage(message)} />
            }</ListItemAvatar>
            <ListItemText>
                <div
                    color={message.UserID !== null || messageIsUserFacing(message) ? "text.primary" : "text.secondary"}
                    style={{ whiteSpace: "pre-line", fontSize: "1.21em" }}
                >
                    {injectCitations(message.Content)}
                </div>
            </ListItemText>
        </ListItem>
    </>
    );
}

export default ChatMessageEntry;
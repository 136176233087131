export interface SessionAuth {
    token: string,
    expiration: string | undefined
}

const SESSION_TOKEN_COOKIE_IDENTIFIER = "sessionToken";
const SESSION_TOKEN_COOKIE_REGEX = /sessionToken=([a-zA-Z0-9\-_\.]+)/;
const TIMESTAMP_REGEX = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/

// session token shouldn't be changed without refreshing
let currentSession: SessionAuth | undefined = undefined;

// TODO: What do we do if the token expires?
export function getCurrentSession(): SessionAuth | undefined {
    if (currentSession !== undefined) return currentSession;
    const match = getSessionTokenCookie();
    if (match == undefined) return undefined
    currentSession = {
        token: match,
        expiration: undefined  // TODO: Get expiration?
    }
    return currentSession;
}

export function bearerSessionToken(): string {
    return "Bearer " + getCurrentSession()?.token
}

function getSessionTokenCookie(): string | undefined {
    const matches = document.cookie.match(SESSION_TOKEN_COOKIE_REGEX);
    if (matches === null) return undefined;
    return matches[1];
}

export function setSessionTokenAndRefresh(token: string, expiration: string) {
    document.cookie = `${SESSION_TOKEN_COOKIE_IDENTIFIER}=${token}; expires=${(new Date(expiration)).toUTCString()}; path=/`;
    document.location.reload();
}

export function logoutAction() {
    document.cookie = "sessionToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"
    window.location.reload();
}
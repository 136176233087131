import { FunctionComponent, useState, ReactNode, useEffect, useContext, Fragment } from "react";

import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import AssetContext from "contexts/AssetContext";
import { AssetCategorizationMap, AssetManufacturerProps, AssetModelProps, AssetSubTypeProps, AssetTypeProps } from "utils/AssetUtils";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { createNewModel } from "utils/AssetBackendRequests";

import ManufacturerSelection from "./ManufacturerSelection";

interface ModelCreationModalProps {
    isOpen: boolean;
    handleClose: () => void;
    typeId: number | undefined;  // Undefined gets converted to 0 in useEffect on props.isOpen
    subtypeId: number | undefined;  // Same as above
    categories: AssetCategorizationMap;
    refreshAssetData: (callback?: () => void) => void;
}

const ModelCreationModal: FunctionComponent<ModelCreationModalProps> = (props): JSX.Element => {
    const assetCtx = useContext(AssetContext);

    const [displayName, setDisplayName] = useState<string>("");
    const [manufacturerId, setManufacturerId] = useState<number>(0);
    const [typeId, setTypeId] = useState<number>(0);
    const [subtypeId, setSubtypeId] = useState<number | undefined>(0);
    const [isPrivate, setPrivate] = useState<boolean>(true);

    const [isLoading, setLoading] = useState<boolean>(false)

    const incompleteForm: boolean = displayName.length === 0
        || typeId === undefined
        || subtypeId === undefined
        || manufacturerId === undefined

    useEffect(() => {
        setTypeId(props.typeId ?? 0);
        setSubtypeId(props.subtypeId ?? 0);
    }, [props.isOpen, props.typeId, props.subtypeId])

    // useEffect(() => {
    //     console.log("t: " + typeId)
    //     console.log("st: " + subtypeId)
    // }, [typeId, subtypeId])

    function submitEvent() {
        setLoading(true);
        if (incompleteForm) return;

        // Coercing already covered by incompleteForm
        createNewModel(displayName, manufacturerId!, typeId!, subtypeId!, !isPrivate).then((status) => {
            if (status === 200) {
                setDisplayName("")
                setManufacturerId(0)
                setTypeId(0)
                setSubtypeId(0)
                setPrivate(true);
                props.handleClose();
            }
        }).finally(() => {
            setLoading(false);
        });
    }

    // TODO: Bad and slow
    function renderSubtypeSelect(): (JSX.Element | null)[] | null {
        let subtypesMap: Map<AssetSubTypeProps | undefined, AssetModelProps[]> | undefined = undefined;

        for (const [key, value] of props.categories.entries()) {
            if (key?.id === typeId) {
                subtypesMap = value;
                break;
            }
        }
        if (subtypesMap === undefined) return null;
        return Array.from(subtypesMap.keys()).map((st: AssetSubTypeProps | undefined, i: number) => {
            if (st === undefined) return null;
            return <MenuItem
                key={i}
                value={st.id}
            >
                <Typography>
                    {st.name}
                </Typography>
            </MenuItem>;
        });
    }

    function renderTypeSelect(): (JSX.Element | null)[] | null {
        return Array.from(assetCtx.assetTypes).map((pair: [number, AssetTypeProps], i: number) =>
            <MenuItem
                key={i}
                value={pair[1].id}
            >
                <Typography>
                    {pair[1].name}
                </Typography>
            </MenuItem>
        )
    }

    function ModalWrapper(innerElement: ReactNode): JSX.Element {
        return <Modal
            open={props.isOpen}
            onClose={props.handleClose}
            closeAfterTransition
        >
            <Fade in={props.isOpen}>
                <div className="model-creation-modal-container">
                    <Paper className="model-creation-modal">
                        <div className="frsbc">
                            <Typography variant="h5">Create new model</Typography>
                            <IconButton onClick={props.handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <Divider sx={{ mb: 1, mt: 1 }} />
                        <div className="model-creation-form-container">
                            {innerElement}
                        </div>
                        <div className="chat-creation-buttons-container">
                            <Button variant="outlined" onClick={props.handleClose}>
                                Cancel
                            </Button>
                            <Button
                                disabled={isLoading || incompleteForm}
                                type="submit"
                                variant="contained"
                                color="primary"
                                onClick={submitEvent}
                            >
                                Submit
                            </Button>
                        </div>
                    </Paper>
                </div>
            </Fade>
        </Modal >;
    }

    function renderModalForm(): JSX.Element {
        if (!props.isOpen) return <></>
        return <div>
            <FormGroup style={{
                paddingTop: "1em",
                paddingBottom: "1em",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                gap: "0.8em",
                scrollbarWidth: "none"
            }}>

                {/* Model name */}
                <FormControl fullWidth>
                    <TextField
                        label="Model name"
                        variant="outlined"
                        value={displayName}
                        inputProps={{
                            maxLength: 256,
                        }}
                        onChange={(e) => {
                            setDisplayName(e.target.value)
                        }}
                    />
                </FormControl>
                <Divider />

                {/* Model type */}
                <FormControl>
                    <InputLabel id="model-creation-type-select-label">Type</InputLabel>
                    <Select
                        labelId="model-creation-type-select-label"
                        label="Type"
                        value={typeId}
                        defaultValue={0}
                        onChange={(e) => {
                            // @ts-ignore
                            setTypeId(parseInt(e.target.value))
                            setSubtypeId(0);
                        }}
                    >
                        <MenuItem value={0}>
                            <Typography>
                                None
                            </Typography>
                        </MenuItem>
                        {renderTypeSelect()}
                    </Select>
                </FormControl>

                {/* Model subtype */}
                <FormControl>
                    <InputLabel id="model-creation-subtype-select-label">Subtype</InputLabel>
                    <Select
                        labelId="model-creation-subtype-select-label"
                        label="Subtype"
                        value={subtypeId}
                        defaultValue={0}
                        onChange={(e) => {
                            // @ts-ignore
                            setSubtypeId(parseInt(e.target.value))
                        }}
                    >
                        <MenuItem value={0}>
                            <Typography>
                                None
                            </Typography>
                        </MenuItem>
                        {renderSubtypeSelect()}
                    </Select>
                </FormControl>

                {/* Model manufacturer */}
                <ManufacturerSelection
                    manufacturerId={manufacturerId}
                    setManufacturerId={setManufacturerId}
                    manufacturers={assetCtx.assetManufacturers}
                    refreshAssetData={props.refreshAssetData}
                />
                <FormControlLabel disabled control={
                    <Checkbox
                        checked={true}   // TODO: Forced private selection
                        onChange={(e) => {
                            setPrivate(e.target.checked);
                        }}
                    />
                } label="Private" />
            </FormGroup>
        </div>
    }

    return ModalWrapper(renderModalForm());
}

export default ModelCreationModal;
import "App.css";
import { FunctionComponent, useContext, useEffect, useState } from "react";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import ListItemButton from "@mui/material/ListItemButton";
import Snackbar from "@mui/material/Snackbar";

import AddBoxIcon from "@mui/icons-material/AddBox";
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import AppContext, { AppContextProps } from "contexts/AppContext";
import ChatInstanceEntry from "./ChatInstanceEntry";
import ChatCreationModal from "./ChatCreationModal";
import { ChatInstance } from "utils/ChatUtils";
import { deleteChatInstance, listChatInstances } from "utils/ChatBackendRequests";
import { ThemedAccordion, ThemedAccordionDetails, ThemedAccordionSummary } from "../LeftSidebar";

interface ChatAccordionProps {

}

const ChatAccordion: FunctionComponent<ChatAccordionProps> = (props): JSX.Element => {
    const ctx: AppContextProps = useContext(AppContext);

    const [deleteSnackbarOpen, setDeleteSnackbarOpen] = useState<boolean>(false);
    const [chatCreationModalOpen, setChatCreationModalOpen] = useState<boolean>(false);

    const [chatList, setChatList] = useState<ChatInstance[]>([]);

    function updateChatInstancesList() {
        if (ctx.userId === undefined) {

        } else {
            listChatInstances()
                .then((data) => { setChatList(data); })
                .catch(error => console.log(error));
        }
    }


    function closeChatCreationModal() {
        setChatCreationModalOpen(false);
        updateChatInstancesList();
    }

    function openChatCreationModal() {
        setChatCreationModalOpen(true);
    }

    function handleEdit() {

    }

    function handleDelete() {
        if (ctx.chatId === undefined) return;
        deleteChatInstance(ctx.chatId).then(() => {
            setDeleteSnackbarOpen(true);
            updateChatInstancesList();
            ctx.setChatId(undefined);
        }).catch(error => console.log(error));
    }

    useEffect((): void => {
        updateChatInstancesList()
    }, [])

    return <>
        <Snackbar
            open={deleteSnackbarOpen}
            autoHideDuration={8000}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
            }}
            onClose={() => { setDeleteSnackbarOpen(false); }}
            message="Chat deleted"
        />

        <ChatCreationModal isOpen={chatCreationModalOpen} handleClose={closeChatCreationModal} />

        <ThemedAccordion>
            <ThemedAccordionSummary expandIcon={<ExpandMoreIcon />}>
                <QuestionAnswerIcon color="primary" />&ensp;<Typography>Chats</Typography>
            </ThemedAccordionSummary>
            <ThemedAccordionDetails sx={{
                p: 0
            }}>
                <List sx={{ pt: 0 }}>
                    <ListItem disablePadding divider>
                        <ListItemButton onClick={() => { openChatCreationModal() }}
                            style={{
                                border: "1px #ddd solid",
                                margin: "8px",
                                borderRadius: "4px",
                                backgroundColor: "#f0f0f0"
                                // backgroundColor: "#e8eaff", // Very light purple
                                // borderColor: "#28387833",
                            }}>
                            <AddBoxIcon /> &ensp;
                            <strong>Start&nbsp;new&nbsp;chat</strong>
                        </ListItemButton>
                    </ListItem>
                    {chatList.map((ci, i) => <ChatInstanceEntry handleEdit={handleEdit} handleDelete={handleDelete} key={i} chat={ci} />)}
                </List>
            </ThemedAccordionDetails>
        </ThemedAccordion>
    </>
}

export default ChatAccordion;
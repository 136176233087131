import { createContext } from 'react';

export interface AppContextProps {
    chatId: number | undefined;
    setChatId: (arg0: number | undefined) => void;
    userId: number | undefined;
    setUserId: (arg0: number | undefined) => void;
    verbosity: number;
    setVerbosity: (arg0: number) => void;
    modelArchitecture: string;
    setModelArchitecture: (arg0: string) => void;
    foo: string;
}

const AppContext = createContext<AppContextProps>({
    chatId: undefined,
    setChatId: () => { },
    userId: undefined,
    setUserId: () => { },
    verbosity: 1,
    setVerbosity: () => { },
    modelArchitecture: "foreman",
    setModelArchitecture: () => { },

    foo: ""
});

export default AppContext;
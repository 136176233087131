import * as ast from "utils/AssetUtils";
import ENDPOINT from "./EndpointDefinition";
import { bearerSessionToken } from "./Session";

async function fetchDescriptorLists(): Promise<any> {
    const response = await fetch(`${ENDPOINT}/Asset/descriptorlists`,
        {
            method: "GET",
            mode: "cors",
            headers: {
                "accept": "application/json",
                "Authorization": bearerSessionToken()
            }
        })

    return await response.json();
}

export async function createNewType(
    displayName: string,
    isPublic: boolean
): Promise<number> {
    const response = await fetch(`${ENDPOINT}/Asset/type` +
        `?displayName=${encodeURIComponent(displayName)}` +
        `&isPublic=${isPublic}`,
        {
            method: "POST",
            mode: "cors",
            headers: {
                "accept": "*/*",
                "Authorization": bearerSessionToken()
            }
        }
    );
    return response.status;
}


export async function createNewSubType(
    displayName: string,
    typeId: number | null,
    isPublic: boolean
): Promise<number> {
    const response = await fetch(`${ENDPOINT}/Asset/subtype` +
        `?displayName=${encodeURIComponent(displayName)}` +
        `&assetTypeId=${typeId ?? 0}` +
        `&isPublic=${isPublic}`,
        {
            method: "POST",
            mode: "cors",
            headers: {
                "accept": "*/*",
                "Authorization": bearerSessionToken()
            }
        }
    );
    return response.status;
}

export async function createNewManufacturer(
    displayName: string,
    isPublic: boolean
): Promise<number> {
    const response = await fetch(`${ENDPOINT}/Asset/manufacturer` +
        `?displayName=${encodeURIComponent(displayName)}` +
        `&isPublic=${isPublic}`,
        {
            method: "POST",
            mode: "cors",
            headers: {
                "accept": "*/*",
                "Authorization": bearerSessionToken()
            }
        }
    );
    return response.status;
}

export async function createNewModel(
    displayName: string,
    manufacturerId: number,
    typeId: number,
    subtypeId: number,
    isPublic: boolean
): Promise<number> {
    const response = await fetch(`${ENDPOINT}/Asset/model` +
        `?displayName=${encodeURIComponent(displayName)}` +
        `&assetManufacturerId=${manufacturerId}` +
        `&assetTypeId=${typeId}` +
        (subtypeId === 0 ? "" : `&assetSubTypeId=${subtypeId}`) +
        `&isPublic=${isPublic}`,
        {
            method: "POST",
            mode: "cors",
            headers: {
                "accept": "*/*",
                "Authorization": bearerSessionToken()
            }
        }
    );
    return response.status;
}

export async function createNewAsset(
    displayName: string,
    modelId: number
): Promise<number> {
    const response = await fetch(`${ENDPOINT}/Asset/asset` +
        `?displayName=${encodeURIComponent(displayName)}` +
        `&assetModelId=${modelId}`,
        {
            method: "POST",
            mode: "cors",
            headers: {
                "accept": "*/*",
                "Authorization": bearerSessionToken()
            }
        }
    );
    return response.status;
}

export async function updateAsset(
    assetId: number,
    displayName: string
): Promise<number> {
    const response = await fetch(`${ENDPOINT}/Asset/asset` +
        `?id=${assetId}` +
        `&displayName=${encodeURIComponent(displayName)}`,
        {
            method: "PUT",
            mode: "cors",
            headers: {
                "accept": "*/*",
                "Authorization": bearerSessionToken()
            }
        }
    );
    return response.status;
}

export async function deleteAsset(
    assetId: number,
): Promise<number> {
    const response = await fetch(`${ENDPOINT}/Asset/asset` +
        `?id=${assetId}`,
        {
            method: "DELETE",
            mode: "cors",
            headers: {
                "accept": "*/*",
                "Authorization": bearerSessionToken()
            }
        }
    );
    return response.status;
}


export async function getDescriptorLists(): Promise<{
    assetTypes: ast.AssetTypeProps[];
    assetSubTypes: ast.AssetSubTypeProps[];
    assetManufacturers: ast.AssetManufacturerProps[];
    assetModels: ast.AssetModelProps[];
} | undefined> {
    try {
        const result: any = await fetchDescriptorLists();
        return {
            assetTypes: result.AssetTypes.map(ast.createAssetType),
            assetSubTypes: result.AssetSubTypes.map(ast.createAssetSubType),
            assetManufacturers: result.AssetManufacturers.map(ast.createAssetManufacturer),
            assetModels: result.AssetModels.map(ast.createAssetModel),
        };
    } catch (ex) {
        console.log(ex);
        return undefined;
    }
}

export async function getAssetList(): Promise<ast.AssetProps[] | undefined> {
    try {
        const result: any = await fetch(`${ENDPOINT}/Asset/list`, {
            method: "GET",
            mode: "cors",
            headers: {
                "accept": "application/json",
                "Authorization": bearerSessionToken()
            }
        });

        return (await result.json()).map(ast.createAsset);
    } catch (ex) {
        console.log(ex);
        return undefined;
    }
}

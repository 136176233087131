import "App.css";

import { FunctionComponent, useState, useEffect, useContext } from "react";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ManagementSidebar from "./ManagementSidebar";
import DocumentContext from "contexts/DocumentContext";
import Divider from "@mui/material/Divider";

import PdfDocument from "./PdfDocument";
import { getDocumentUrl } from "utils/DocumentBackendRequests";

interface RightSidebarProps {
    chatId: number | undefined
}

const RightSidebar: FunctionComponent<RightSidebarProps> = (props): JSX.Element => {
    const docCtx = useContext(DocumentContext);

    // null is unauthorized, undefined is no selection
    const [documentUrl, setDocumentUrl] = useState<string | undefined | null>(undefined);

    useEffect(() => {
        if (docCtx.documentId !== undefined) {
            getDocumentUrl(docCtx.documentId).then((url) => {
                setDocumentUrl(url);
            }).catch(error => {
                console.log(error)
            });
        }

    }, [docCtx.documentId])


    return <div className="right-sidebar-container">
         <div>
            <Tabs value={docCtx.tabValue} onChange={(event, val) => {
                docCtx.setTabValue(val);
            }}>
                <Tab label="Asset" value={0} aria-controls="tab-panel-1" />
                <Tab label="Document" value={1} aria-controls="tab-panel-2" />
            </Tabs>
            <Divider />
        </div>
        {docCtx.tabValue === 0 &&
            <ManagementSidebar />
        }

        {docCtx.tabValue === 1 &&
            <PdfDocument documentUrl={documentUrl} documentPageRefs={docCtx.documentPageRefs} />
        }
    </div>;
}

export default RightSidebar;
import "App.css";
import "./styles.css";

import { FunctionComponent } from "react";
import { styled } from "@mui/material/styles";

import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

import { ChatAccordion } from "./ChatAccordion";
import { SettingsAccordion } from "./SettingsAccordion";

import { DocumentAccordion } from "./DocumentAccordion";
import AssetAccordion from "./AssetAccordion/AssetAccordion";
import BrandingCard from "components/BrandingCard";

interface LeftSidebarProps {
    logoutAction: () => void;
}

const ThemedAccordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => {
    const borderStyle: string = "1px solid #bfbeca"
    return {
        border: borderStyle,
        borderBottom: "1px solid #bfbeca77",
        '&:nth-of-type(2)': {
            borderRadius: "8px 8px 0 0",
        },
        '&:last-child': {
            borderRadius: "0 0 8px 8px",
            borderBottom: borderStyle
        },
        '&:before': {
            display: 'none',
        },
        "&.Mui-expanded": {
            marginTop: "1.25em",
            marginBottom: "1.25em",
            borderRadius: "8px",
            borderBottom: borderStyle
        },
    }
});

const ThemedAccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary {...props} />
))(({ theme }) => ({
    backgroundColor: "#f0f0f0",
    borderRadius: "6px"

}));

const ThemedAccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const LeftSidebar: FunctionComponent<LeftSidebarProps> = (props): JSX.Element => {
    return <div className="left-sidebar-container">
        <BrandingCard logoutAction={props.logoutAction}/>
        <ChatAccordion />
        <AssetAccordion />
        <DocumentAccordion />
        <SettingsAccordion />
    </div>;
}

export default LeftSidebar;
export { ThemedAccordion, ThemedAccordionDetails, ThemedAccordionSummary };

import "App.css";
import "./styles.css";
import { FunctionComponent, useState, useContext, useRef, useEffect, MutableRefObject } from "react";


import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";

import IconButton from "@mui/material/IconButton";
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import FullscreenIcon from '@mui/icons-material/Fullscreen';

import { ChatMessage } from "utils/ChatUtils";
import ChatMessageEntry from "./ChatMessageEntry";
import { getUserSpending, readChatHistory } from "utils/ChatBackendRequests";
import AppContext, { AppContextProps } from "contexts/AppContext";
import { createChatMessage, startPromptTree } from "utils/ChatBackendRequests";
import ChatInputBox from "./ChatInputBox";


const REFRESH_INTERVAL_WHEN_ACTIVE = 2504
const QUERYING_OUTPUT_REGEX = /^{.+}$/;
const FOREMAN_AGENT_INVOCATION_REGEX = /\[[tT]o/;

const WARNING_THRESHOLD = 11.25;
const QUOTA_THRESHOLD = 15.0;

interface ChatProps {
    focusMode: boolean;
    setFocusMode: (arg0: boolean) => void;
}

function renderPlaceholder(): JSX.Element {
    return <div style={{ margin: "2rem" }}>
        <Typography>
            Select a chat instance to start.
        </Typography>
    </div>;
}

export function messageIsUserFacing(msg: ChatMessage): boolean {
    // !!!!!!!!!!!!!!!!!!!!!!!!! TODO: Agent ID hardcode !!!!!!!!!!!!!!!!!!!!!!!!!!!
    // Coupled with C# backend PromptEngine.cs and EngineUtils.cs
    if (msg.AgentID === null) {
        return true;
    } else if (msg.AgentID === 123456000) {
        return !FOREMAN_AGENT_INVOCATION_REGEX.test(msg.Content);
    } else if (msg.AgentID === 123456005) {
        return !QUERYING_OUTPUT_REGEX.test(msg.Content);
    } else return false;
}

function quotaPercentage(spending: number): number {
    return Math.round(spending / (QUOTA_THRESHOLD + 0.06) * 10000) / 100
}

function renderWarning(spending: number): JSX.Element {
    const defaultStyle = { fontSize: "0.85rem" }
    if (spending >= QUOTA_THRESHOLD) {
        return <Typography
            sx={{
                ...defaultStyle
            }}
            lineHeight={1}
            color="text.secondary"
            noWrap
        >
            You have used all of your free quota. Come back later or contact us to request more.
        </Typography>
    } else if (spending >= WARNING_THRESHOLD) {
        return <Typography
            sx={{
                ...defaultStyle
            }}
            lineHeight={1}
            color="text.secondary"
            noWrap
        >   
            {quotaPercentage(spending)}% of free quota used, now using a downscaled architecture.
        </Typography>
    } else return <></>
}

function renderChatHistory(chatHistory: ChatMessage[], verbosity: number, historyBottomRef: MutableRefObject<any>, loaded: boolean): JSX.Element {
    if (loaded && chatHistory.length == 0) return <div style={{ margin: "2rem" }}>
        <Typography>
            This chat is currently empty, send a message to start the conversation.
        </Typography>
    </div>;

    return <Stack
        className="chat-history-stack"
        justifyContent="flex-start"
    >
        {chatHistory.map((m: ChatMessage, i: number) => {
            if (verbosity < 2 && m.AgentID !== null && (m.IsInternal || QUERYING_OUTPUT_REGEX.test(m.Content))) return null;
            if (m.AgentID !== null && verbosity < 1 && !messageIsUserFacing(m)) return null;
            return <div key={i}>
                <ChatMessageEntry message={m} />
            </div>
        }
        )}
        <div ref={historyBottomRef}></div>
    </Stack>;
}

const Chat: FunctionComponent<ChatProps> = (props): JSX.Element => {
    const ctx: AppContextProps = useContext(AppContext);
    const [isProcessing, setActive] = useState<boolean>(false);
    const [loadedChatHistory, setLoadedChatHistory] = useState<boolean>(false);
    const [chatHistory, setChatHistory] = useState<ChatMessage[]>([]);
    const [spending, setSpending] = useState<number | undefined>(undefined);

    const historyBottomRef = useRef<any>(null);

    function scrollToLatest() {
        if (historyBottomRef.current == null) return;
        historyBottomRef.current.scrollIntoView({ behavior: "smooth" });
    }


    function updateChatHistory() {
        if (ctx.chatId === undefined || ctx.userId === undefined) return;

        getUserSpending().then((result) => {
            setSpending(result);
        }).catch(error => {
            console.log(error)
        });

        const requestedChatId = ctx.chatId;
        readChatHistory(requestedChatId)
            .then(result => {
                setLoadedChatHistory(true);
                offerSetChatHistory(result, requestedChatId);
            })
            .catch(error => {
                console.log(error);
            });
    }

    function offerSetChatHistory(
        newHistory: ChatMessage[],
        validatorChatId: number,
    ): void {
        if (validatorChatId !== ctx.chatId) {
            console.log("Enqueue offer rejected")
            return;  // Reject
        }

        // console.log(newHistory)
        // console.log(chatHistory)
        // console.log(newHistory.length !== chatHistory.length)
        // if (newHistory !== chatHistory) {
        setChatHistory(newHistory);
        //     setTimeout(() => { scrollToLatest() }, 500)
        // };
    }

    function handleChatMessageSubmit(content: string) {
        if (ctx.chatId === undefined || ctx.userId === undefined) return;
        createChatMessage(ctx.chatId, content)
            .catch((error) => {
                console.log(error)
            })
            .then(() => {
                setActive(true);
                setTimeout(() => { scrollToLatest() }, 600)
                updateChatHistory();

                // Definitely not undefined
                startPromptTree(ctx.chatId!, ctx.modelArchitecture).catch((error) => {
                    console.log(error)
                }).then(() => {
                    setActive(false);
                    setTimeout(() => { scrollToLatest() }, 200)
                }).finally(() => {
                    updateChatHistory();
                })
            });
    }
    // Updates chat history every few seconds
    useEffect(() => {
        if (isProcessing) {
            const interval = setInterval(updateChatHistory, REFRESH_INTERVAL_WHEN_ACTIVE);
            return () => { clearInterval(interval); }
        }
    }, [isProcessing])

    // Listens to whenever the chatId changes
    useEffect((): void => {
        setLoadedChatHistory(false);
        setActive(false);
        setChatHistory([]);
        updateChatHistory();
        setTimeout(() => { scrollToLatest() }, 600);
        // scrollToLatest()
    }, [ctx.chatId])

    return <div
        className={"chat-container " + (props.focusMode ? "chat-container-full" : "chat-container-default")}
        style={{

        }}>
        {/* Input box */}
        <Card elevation={6} className="chat-input-box">
            <ChatInputBox
                disabled={ctx.chatId === undefined}
                isProcessing={isProcessing}
                handleChatMessageSubmit={handleChatMessageSubmit}
                quotaLimit={spending !== undefined && spending >= QUOTA_THRESHOLD}
            />
            {
                spending !== undefined && renderWarning(spending)
            }
        </Card>

        {/* Chat history */}
        <Paper elevation={6} className="chat-history-container">
            {
                ctx.chatId === undefined ?
                    renderPlaceholder() :
                    renderChatHistory(chatHistory, ctx.verbosity, historyBottomRef, loadedChatHistory)
            }
        </Paper>

        {/* Focus button */}
        <IconButton
            style={{
                position: "absolute",
                border: "1px solid #ddd5",
                top: 0,
                right: 0,
                padding: 3,
                borderRadius: "6px",
                margin: "0.875em",
                backgroundColor: "#f0f0f033"
            }}
            onClick={() => {
                props.setFocusMode(!props.focusMode);

            }}
        >
            {props.focusMode
                ? <FullscreenExitIcon color="primary" />
                : <FullscreenIcon color="primary" />
            }
        </IconButton>
    </div>;
}
export default Chat;
import { FunctionComponent, memo } from "react";
import { Box, Button, ListItem, Typography } from "@mui/material";

import AddBoxIcon from "@mui/icons-material/AddBox";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { FolderAccordion, FolderAccordionSummary, FolderAccordionDetails } from "../FolderAccordions";
import { AssetManufacturerProps, AssetModelProps, AssetSubTypeProps } from "utils/AssetUtils";
import VisibilityButton from "./VisibilityButton";
import AssetModelEntry from "./AssetModelEntry";

interface AssetSubTypeEntryProps {
    subtype: AssetSubTypeProps | undefined;
    models: AssetModelProps[];
    manufacturers: Map<number, AssetManufacturerProps>;
    selectedModelId: number;
    setModel: (arg0: number) => void;
    handleOpenModelCreation: (typeId: number | undefined, subTypeId: number | undefined) => void;
}

const AssetSubTypeEntry: FunctionComponent<AssetSubTypeEntryProps> = memo((props): JSX.Element => {
    return <>
        <FolderAccordion>
            <FolderAccordionSummary className="frsbc subtype-accordion-summary" expandIcon={<ExpandMoreIcon />}>
                <div className="subtype-accordion-summary-label">
                    <VisibilityButton
                        isPublicShared={false}
                        isPublicVisible={props.subtype?.isPublic ?? true}
                        fontSize="small"
                    />
                    <Typography
                        className="subtype-name-typography"
                    >
                        <strong>{props.subtype?.name ?? "Uncategorized"}</strong>
                    </Typography>
                </div>
                <Box>
                    {props.models.length === 0
                        ? <Typography noWrap
                            className="create-model-typography-inline"
                            onClick={(e) => {
                                e.stopPropagation();
                                props.handleOpenModelCreation(
                                    props.subtype?.assetTypeId,
                                    props.subtype?.id
                                );
                            }}
                        >
                            Create model
                        </Typography>
                        : <Typography noWrap color="text.secondary" className="subtype-model-count-typography">
                            {props.models.length} models
                        </Typography>
                    }
                </Box>
            </FolderAccordionSummary>
            <FolderAccordionDetails>
                {props.models.map((model: AssetModelProps, i: number) =>
                    <AssetModelEntry
                        model={model}
                        key={i}
                        isSelected={model.id === props.selectedModelId}
                        manufacturerName={props.manufacturers.get(model.manufacturerId)?.name}
                        setModel={props.setModel}
                    />
                )}
                <ListItem className="create-model-button-entry">
                    {props.models.length === 0
                        ? <Typography color="text.secondary" className="no-model-typography">
                            No models in this subtype
                        </Typography>
                        : <Button
                            className="frsbc create-model-button"
                            onClick={() => {
                                props.handleOpenModelCreation(
                                    props.subtype?.assetTypeId,
                                    props.subtype?.id
                                )
                            }}
                        >
                            <AddBoxIcon fontSize="small" color="primary" />
                            <Typography className="create-model-button-typography" noWrap>
                                Create new model
                            </Typography>
                        </Button>
                    }
                </ListItem>
            </FolderAccordionDetails>
        </FolderAccordion >
    </>
})

export default AssetSubTypeEntry;
import { FunctionComponent, useState } from "react";

import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { sendResetPasswordLink } from "utils/AuthRequests";


interface ResetPasswordFormProps {
    setFormTypeValue: (arg0: number) => void;
}

const ResetPasswordForm: FunctionComponent<ResetPasswordFormProps> = (props): JSX.Element => {
    const [email, setEmail] = useState<string>("");
    const [done, setDone] = useState<boolean>(false);
    const [inProgress, setInProgress] = useState<boolean>(false);

    function sendResetLink() {
        if (email.length == 0) return;

        setInProgress(true);
        sendResetPasswordLink(email).then((response) => {
            setDone(true);
            setInProgress(false);
        })
    }

    return (<FormGroup className="login-page-form-group" onKeyDown={(event) => {
        if (event.key === 'Enter' && !event.shiftKey && !event.ctrlKey) {
            sendResetLink();
        }
    }}>
        <div>
            <Typography
                color="text.primary"
                style={{
                    textDecoration: "underline",
                    cursor: "pointer"
                }}
                onClick={() => {
                    props.setFormTypeValue(0);
                }}
            >
                Go back to log in
            </Typography>
        </div>
        <FormControl fullWidth required>
            <TextField
                label="Email"
                variant="outlined"
                value={email}
                inputProps={{ maxLength: 384 }}
                onChange={(e) => {
                    setDone(false);
                    setEmail(e.target.value)
                }}
            />
        </FormControl>
        {done && <Typography>A reset link has been sent to the email provided.</Typography>}
        <Button
            variant="contained"
            fullWidth
            disabled={email.length == 0 || done || inProgress}
            onClick={sendResetLink}>
            Send reset link
        </Button>
    </FormGroup>);
}

export default ResetPasswordForm;
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import defaultTheme from 'theme';

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ResetPasswordPage from 'components/Authentication/ResetPasswordPage';
import { ThemeProvider } from '@mui/material/styles';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/ResetPassword",
    element: <ResetPasswordPage />,
  },
]);


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={defaultTheme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  </React.StrictMode >
);